import React, { useEffect, useState } from "react";
import { FormControlLabel, FormGroup, Checkbox } from "@mui/material";
import TypographyComponent from "../../../../../../../shared/typography/TypographyComponent";
import { useIntl } from "react-intl";
import { TEXT_VARIANT_H2, TEXT_VARIANT_SPAN } from "../../../../../../../shared/typography/constants";
import {
  AUTOMATIC_PRODUCTION_OPTION__LABELS,
  AUTOMATIC_PRODUCTION_OPTION__MICROPHONE,
  AUTOMATIC_PRODUCTION_OPTION__PARTICIPANT,
  AUTOMATIC_PRODUCTION__TITLE,
} from "../../../../../../../../translations/constans";
import style from "./AutomaticProductionOptions.module.css";

const AutomaticProductionOptions = ({ options, setOptions, autoSelectionDisabled = false }) => {
  const intl = useIntl();
  const autoProductionSelection = "autoSelection";
  const autoProductionFraming = "autoFraming";
  const autoProductionTitling = "autoTitling";

  const [autoSelection, setAutoSelection] = useState(true);
  const [autoFraming, setAutoFraming] = useState(true);
  const [autoTitling, setAutoTitling] = useState(true);

  useEffect(() => {
    if (options) {
      if (options.auto_mixing !== autoFraming) setAutoFraming(options.auto_mixing);
      if (options.auto_participant_selection !== autoSelection) setAutoSelection(options.auto_participant_selection);
      if (options.auto_titler !== autoTitling) setAutoTitling(options.auto_titler);
    }
  }, [options]);

  const handleChangeAutomaticProduction = (autoOption) => {
      let autoMixing = autoFraming;
      let autoParticipantSelection = autoSelection;
      let autoTitler = autoTitling;
      if (autoOption === autoProductionFraming) {
          setAutoFraming(!autoFraming);
          autoMixing = !autoFraming
      }
      if (autoOption === autoProductionSelection) {
          setAutoSelection(!autoSelection)
          autoParticipantSelection = !autoParticipantSelection;
      }
      if (autoOption === autoProductionTitling) {
         setAutoTitling(!autoTitling)
         autoTitler = !autoTitling;
      }
      let newOptions = {
          auto_mixing: autoMixing,
          auto_participant_selection: autoParticipantSelection,
          auto_titler: autoTitler,
      };
      setOptions(newOptions);
  }

  return (
    <div className={style.automaticRealizationContainer}>
      <TypographyComponent text={intl.formatMessage({ id: AUTOMATIC_PRODUCTION__TITLE })} variant={TEXT_VARIANT_H2} />
      <FormGroup
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "var(--spacing-xl)",
          justifyContent: "space-around",
        }}
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={autoSelection}
              onChange={() => { handleChangeAutomaticProduction(autoProductionSelection) }}
              sx={{
                "&.Mui-checked": {
                  color: "var(--color-primary)",
                },
              }}
            />
          }
          disabled={autoSelectionDisabled}
          label={
            <TypographyComponent
              text={intl.formatMessage({ id: AUTOMATIC_PRODUCTION_OPTION__MICROPHONE })}
              variant={TEXT_VARIANT_SPAN}
            />
          }
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={autoFraming}
              onChange={() => { handleChangeAutomaticProduction(autoProductionFraming) }}
              sx={{
                "&.Mui-checked": {
                  color: "var(--color-primary)",
                },
              }}
            />
          }
          label={
            <TypographyComponent
              text={intl.formatMessage({ id: AUTOMATIC_PRODUCTION_OPTION__PARTICIPANT })}
              variant={TEXT_VARIANT_SPAN}
            />
          }
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={autoTitling}
              onChange={() => { handleChangeAutomaticProduction(autoProductionTitling) }}
              sx={{
                "&.Mui-checked": {
                  color: "var(--color-primary)",
                },
              }}
            />
          }
          label={
            <TypographyComponent
              text={intl.formatMessage({ id: AUTOMATIC_PRODUCTION_OPTION__LABELS })}
              variant={TEXT_VARIANT_SPAN}
            />
          }
        />
      </FormGroup>
    </div>
  );
};

export default AutomaticProductionOptions;
