export const COMMON__ACCEPT = "common__accept";
export const COMMON_CANCEL = "common__cancel";
export const COMMON__ALL_RIGHTS_RESERVED = "global__all_rights_reserved";
export const COMMON__ERROR_403 = "global__error_403";
export const COMMON__ERROR_404 = "global__error_404";
export const COMMON__GLOBAL_BUTTONS__CLOSE = "global_buttons__close";
export const COMMON__HOME = "common__home";
export const COMMON__INT_CA = "common__intCa";
export const COMMON__INT_EN = "common__intEn";
export const COMMON__INT_ES = "common__intEs";
export const COMMON__INT_GL = "common__intGl";
export const COMMON__INT_EU = "common__intEu";
export const COMMON__LOADING_DESCRIPTION = "common__loading_description";
export const COMMON__LOGIN = "common__login";
export const COMMON__LOGOUT = "common__logout";
export const COMMON__NO = "common__no";
export const COMMON__PRIVATE_PAGE = "common__private_page";
export const COMMON__PRIVACY_POLICY = "common__privacy_policy";
export const COMMON__DELETE = "common__delete";
export const COMMON__EDIT = "common__edit";
export const COMMON_SAVE = "common__save";
export const COMMON__TOTAL_RESULTS = "common__total_results";
export const COMMON__TERMS_OF_USE = "common__terms_of_use";
export const COMMON__PUBLIC_PAGE = "common__public_page";
export const COMMON__YES = "common__yes";
export const COMMON__WELCOME = "common__welcome";
export const COMMON__BACK = "common__back";
export const COMMON__DOWNLOAD = "common__download";
export const COMMON__DOWNLOAD_VIDEO = "common__download_video";
export const COMMON__MP3 = "common__mp3";
export const COMMON__MP4 = "common__mp4";
export const COMMON__HIDE = "common__hide";
export const COMMON__SHOW = "common__show";
export const COMMON_LANGUAGE = "common__language";
export const ADD__PLENARY = "add__plenary";
export const EDIT__PLENARY = "edit__plenary";
export const INFORMATION__PLENARY = "information__plenary";
export const NEW__PLENARY = "new__plenary";
export const NEW__ROOM = "room";
export const ORDINARY__PLENARY = "ordinary__plenary";
export const SELECT__ROOM = "select__room";
export const COMMISSIONS = "commissions";
export const SELECT__COMMISSION = "select__commission";
export const TITLE = "title";
export const WRITE__TITLE = "write__title";
export const SUBTITLE = "subtitle";
export const WRITE__SUBTITLE = "write__subtitle";
export const PRIVATE_SESSION = "private__session";
export const START__DATE = "start__date";
export const END__DATE = "end__date";
export const EMAIL = "email";
export const WRITE__EMAIL = "write__email";
export const ERROR_BOUNDARY__TITLE = "error_boundary__title";
export const ERROR_BOUNDARY__DESCRIPTION = "error_boundary__description";
export const COMMON__START_SESSION = "common__start_session";
export const COMMON__END_SESSION = "common__end_session";
export const LANGUAGE_REALIZATION_LABELS = "language_realization_labels";
export const TITLE_SUBTITLE_REQUIRED_LEGEND = "title_subtitle_required_legend";
export const AND_IN = "and_in";
export const POINT_REQUIRED_LEGEND = "point_required_legend";
export const FORM_ERROR_TITLE_SUBTITLE_REQUIRED = "form_error_title_subtitle_required";
export const COMMON__DOWNLOAD_AUDIO_PLENO = "common__download_audio_pleno";
export const COMMON__AUDIO_UNAVAILABLE = "common__audio_unavailable";

// PLENARY SESSIONS
export const PLENARY__SESSIONS__ASSIGN_TRANSCRIPTS = "plenary__sessions__assign_transcripts";
export const PLENARY__SESSIONS__CHANGE_START__OFFSET__TITLE = "plenary__sessions__change_start__offset__title";
export const PLENARY__SESSIONS__COMMISSION = "plenary__sessions__commission";

export const PLENARY__SESSIONS__COMMISSION_EXTERNAL__URL = "plenary__sessions__external__URL";
export const PLENARY__SESSIONS__DOCUMENT_UPLOAD__PLACE_HOLDER__DOCUMENT_NAME = "plenary__sessions__document_upload__place_holder__document_name";

export const PLENARY__SESSIONS__DOCUMENT_UPLOAD__PLACE_HOLDER__DESCRIPTION =
"plenary__sessions__document_upload__place_holder__description";
export const PLENARY__SESSIONS__DOCUMENT_UPLOAD__PLACE_HOLDER__FILE_NAME =
"plenary__sessions__document_upload__place_holder__file_name";
export const PLENARY__SESSIONS__DOCUMENT_UPLOAD__PLACE_HOLDER__FILE_TYPE =
"plenary__sessions__document_upload__place_holder__file_type";
export const PLENARY__SESSIONS__DOCUMENT_DELETE__CONFIRMATION_MODAL__TITLE =
"plenary__sessions__document_delete__confirmation_modal__title";
export const PLENARY__SESSIONS__DOCUMENT_DELETE__CONFIRMATION_MODAL__TEXT =
"plenary__sessions__document_delete__confirmation_modal__text";
export const PLENARY__SESSIONS__DOCUMENTS = "plenary__sessions__documents";
export const PLENARY__SESSIONS__ADD_DOCUMENTS = "plenary__sessions__add_documents";
export const PLENARY__SESSIONS__DOCUMENTS__UPLOAD = "documents__upload__error";
export const PLENARY__SESSIONS__DOCUMENTS__ERROR__MAX_SIZE_EXCEEDED = "documents__error__size_exceeded";
export const PLENARY__LIST__DELETE_QUESTION = "plenary__list__delete_question";
export const PLENARY__SESSIONS__END_DATE = "plenary__sessions__end_date";
export const PLENARY__SESSIONS__INIT_DATE = "plenary__sessions__init_date";
export const PLENARY__SESSIONS__PARTICIPANTS_TABLE_NAME = "plenary__sessions__participants__table_name";
export const PLENARY__SESSIONS__PARTICIPANTS_TABLE_DESCRIPTION = "plenary__sessions__participants__table_description";
export const PLENARY__SESSIONS__PARTICIPANTS_TABLE_CHARGE = "plenary__sessions__participants__table_charge";
export const PLENARY__SESSIONS__PARTICIPANTS_TABLE_GAME = "plenary__sessions__participants__table_game";
export const PLENARY__SESSIONS__PARTICIPANTS_TABLE_MICROPHONE = "plenary__sessions__participants__table_microphone";
export const PLENARY__SESSIONS__PARTICIPANTS_TABLE_MENU_DECREASING =
"plenary__sessions__participants__table_menu_decreasing";
export const PLENARY__SESSIONS__PARTICIPANTS_TABLE_MENU_UPWARD = "plenary__sessions__participants__table_menu_upward";
export const PLENARY__SESSIONS__PARTICIPANTS_TABLE_MENU_FILTER = "plenary__sessions__participants__table_menu_filter";
export const PLENARY__SESSIONS__PARTICIPANTS_TABLE_MENU_HIDE = "plenary__sessions__participants__table_menu_hide";
export const PLENARY__SESSIONS__PARTICIPANTS_TABLE_MENU_MANAGE_COLUMNS =
"plenary__sessions__participants__table_menu_manage_columns";
export const PLENARY__SESSIONS__PARTICIPANTS_TABLE_ROWS_PER_PAGE =
"plenary__sessions__participants__table_rows_per_page";
export const PLENARY__SESSIONS__PARTICIPANTS_TABLE_OF = "plenary__sessions__participants__table_of";

export const PLENARY__SESSIONS__PARTICIPANTS_CONFIGURATION__ASSIGN_BUTTON =
"plenary__sessions__participants_configuration__assign_button";
export const PLENARY__SESSIONS__PARTICIPANTS_CONFIGURATION__CREATE_PARTICIPANT__BUTTON =
"plenary__sessions__participants_configuration__create_participant__button";
export const PLENARY__SESSIONS__PARTICIPANTS_CONFIGURATION__EDIT_PARTICIPANT__BUTTON =
"plenary__sessions__participants_configuration__edit_participant__button";
export const PLENARY__SESSIONS__PARTICIPANTS_FORM_EMAIL = "plenary__sessions__participants__form__email";
export const PLENARY__SESSIONS__PARTICIPANTS_FORM_IMAGE = "plenary__sessions__participants__form__image";
export const PLENARY__SESSIONS__PARTICIPANTS_FORM_NAME = "plenary__sessions__participants__form__name";
export const PLENARY__SESSIONS__PARTICIPANTS_FORM_ORGANIZATION = "plenary__sessions__participants__form__organization";
export const PLENARY__SESSIONS__PARTICIPANTS_FORM_IN = "plenary__sessions__participants__form__in";
export const PLENARY__SESSIONS__PRIVATE_SESSION = "plenary__sessions__private_session";
export const PLENARY__SESSIONS__ACTION = "plenary__sessions__action";
export const PLENARY__SESSIONS__SITE = "plenary__sessions__site";
export const PLENARY__SESSIONS__STATUS__STATUS = "plenary__sessions__status";
export const PLENARY__SESSIONS__STATUS__CHANGE_NOT_POSSIBLE = "plenary__sessions__status__change_not_possible";
export const PLENARY__SESSIONS__STATUS__MARK_AS_REVIEWED = "plenary__sessions__status__mark_as_reviewed";
export const PLENARY__SESSIONS__STATUS__MARK_AS_IN_REVIEW = "plenary__sessions__status__mark_as_in_review";
export const PLENARY__SESSIONS__STATUS__PUBLISH = "plenary__sessions__status__publish";
export const PLENARY__SESSIONS__STATUS__RETURN_TO_REVIEWED = "plenary__sessions__status__return_to_reviewed";
export const PLENARY__SESSIONS__STATUS__TITLE = "plenary__sessions__status__title";
export const PLENARY__SESSIONS__SUBTITLE = "plenary__sessions__subtitle";
export const PLENARY__SESSIONS__TITLE = "plenary__sessions__title";
export const PLENARY__SESSIONS__RESOLVE_THE_ERROR = "plenary__sessions__resolve_the_error";
export const PLENARY__SESSIONS__PARTICIPANTS_TABLE_NO_MICROPHONE = "plenary__sessions__participants_table_no_microphone";
export const PLENARY_SESSIONS_PARTICIPANTS_TABLE_CAPTION_ENABLED = "plenary_sessions_participants_table_caption_enabled";
export const PLENARY_SESSIONS_PARTICIPANTS_TABLE_CAPTION = "plenary_sessions_participants_table_caption";

//PLENARY LIST
export const PLENARY_LIST__PAGE_TITLE = "plenary_list__page_title";
export const PLENARY_LIST__PAGE_DESCRIPTION = "plenary_list__page_description";
export const PLENARY_LIST__FILTER_SELECT_PLACE = "plenary_list__filter_select_place";
export const PLENARY_LIST__FILTER_SELECT_STATUS = "plenary_list__filter_select_status";

export const PLENARY_LIST__FILTER_SELECT_STATUS_ALL = "plenary_list__filter_select_status_all";

export const PLENARY_LIST__FILTER_SELECT_DATE_FROM = "plenary_list__filter_select_date_from";
export const PLENARY_LIST__FILTER_SELECT_DATE_TO = "plenary_list__filter_select_date_to";

// Botones de reordenar
export const REORDER_BUTTON_ID = "reorder_button_id";
export const TOOLTIP_REORDER_BUTTON_DISABLED = "tooltip_reorder_button_disabled";

//PLENARY SESSIONS STATUS
export const PLENARY_STATUS__IN_REVIEW = "plenary_status__in_review";
export const PLENARY_STATUS__REVIEWED = "plenary_status__reviewed";
export const PLENARY_STATUS__PUBLISHED = "plenary_status__published";
export const PLENARY_STATUS__STARTING = "plenary_status__starting";
export const PLENARY_STATUS__STARTED = "plenary_status__started";
export const PLENARY_STATUS__SCHEDULED = "plenary_status__scheduled";
export const PLENARY_STATUS__FINISHED = "plenary_status__finished";
export const PLENARY_STATUS__CREATED = "plenary_status__created";
export const PLENARY_STATUS__ERROR = "plenary_status__error";
export const PLENARY__SESSIONS__DOCUMENT_UPLOAD__PLACE_HOLDER__PURPOSE = "plenary_sessions_document_upload_place_holder_purpose";
export const FORM_MESSAGE_DOCUMENT_PUERPOSE = "form_message_document_purpose";

//SESSION INFO CARD
export const PLENARY_DETAIL__PAGE_TITLE = "plenary_detail__page_title";
export const SESSION_INFO_CARD__TITLE = "session_info_card__title";
export const SESSION_INFO_ERROR = "session_info_error";
export const BUTTON_DELETE_PLENARY_DESCRIPTION = "button_delete_plenary_description";
export const GO_TO_MIXER = "go_to_mixer";
export const GO_TO_MIXER_DESCRIPTION = "go_to_mixer_description";
export const ADVANCED_CONFIG = "advanced_config";
export const ADVANCED_CONFIG_DESCRIPTION = "advanced_config_description";
export const BUTTON_DISABLED_BY_SESSION_STATUS = "button_disabled_by_session_status";
export const BUTTON_DISABLED_BY_USER_PERMISSION = "button_disabled_by_user_permission";

//SESSION AGENDA
export const SESSION_AGENDA__TITLE = "session_agenda__title";
export const SESSION_AGENDA__ADD_ITEM = "session_agenda__add_item";
export const SESSION_AGENDA_MODAL_FINISH_EVENT = "session_agenda_modal_finish_event";

export const VOTE_RESULT_YES = "vote_result_yes";
export const VOTE_RESULT_NO = "vote_result_no";
export const VOTE_RESULT_ABSTENTION = "vote_result_abstention";
export const PLENARY_VOTING__RESULT__BUTTON = "plenary_voting__result__button";
export const UNNAMED_POINT = "unnamed_point";
export const SESSION_BREAK = "session_break";
export const TOOLTIP_BREAK_DISABLED = "tooltip_break_disabled";
export const TOOLTIP_GENERAL_VIEW_ENABLED = "tooltip_general_view_enabled";
export const TOOLTIP_GENERAL_VIEW_DISABLED = "tooltip_general_view_disabled";
export const GENERAL_VIEW_BUTTON = "general_view_button";
export const TOOLTIP_REFRESH_PAGE = "tooltip_refresh_page";
export const TOOLTIP_AGENDA_POINTS = "tooltip_agenda_points";
export const MESSAGE_ACTIVE_POINT_MODAL = "message_active_point_modal";

//AUTOMATIC PRODUCTION
export const AUTOMATIC_PRODUCTION__TITLE = "automatic_production__title";
export const AUTOMATIC_PRODUCTION_OPTION__MICROPHONE = "automatic_production_option__microphone";
export const AUTOMATIC_PRODUCTION_OPTION__PARTICIPANT = "automatic_production_option__participant";
export const AUTOMATIC_PRODUCTION_OPTION__LABELS = "automatic_production_option__labels";

//PARTICIPANTS
export const PARTICIPANTS_CONFIG__TITLE = "participants_config__title";
export const PARTICIPANTS_CONFIG__ADD_PARTICIPANT = "participants_config__add_participant";
export const PARTICIPANTS_CONFIG__SELECT_PARTICIPANT = "participants_config__select_participant";
export const PARTICIPANTS_CONFIG__LOADING_PARTICIPANTS = "participants_config__loading_participants";
export const PARTICIPANTS_CONFIG__NO_PARTICIPANTS = "participants_config__no_participants";
export const NOT_DATA = "not_data";
export const PARTICIPANTS__CLOSE_UP = "participants__close_up";
export const PARTICIPANTS__CANT_CHANGE = "participants__cant_change";
export const PARTICIPANTS__SPECIAL_CANT_CHANGE = "participants__special_cant_change";
export const DELETE_PARTICIPANTS = "delete_participants";
export const MESSAGE_DELETE_PARTICIPANTS = "message_delete_participants";
export const MESSAGE_DELETE_AND_EDIT_PARTICIPANTS_WITH_CHANGES = "message_delete_and_edit_participants_with_changes";
export const SAVE_AND_CLOSE = "save_and_close";
export const MESSAGE_TOOLTIP_DELETE_PARTICIPANTS = "message_tooltip_delete_participants";
export const MESSAGE_TOOLTIP_EDIT_PARTICIPANTS = "message_tooltip_edit_participants";
export const PARTICIPANTS_TYPE_RECESS = "participants_type_recess";
export const PARTICIPANTS_TYPE_VOTE = "participants_type_vote";
export const PARTICIPANTS_TYPE_PUBLIC = "participants_type_public";
export const PARTICIPANTS_TYPE_MULTIPLE_MICROPHONES_SIMULTANEOUSLY = "participants_type_multiple_microphones_simultaneously";
export const CREATE_PARTICIPANT_REQUIRED_LEGEND = 'create_participant_required_legend';

//INTERVINIENTES
export const PARTICIPANTS__TITLE = "participants__title";

//MINUTAJE

export const PLENARY_NEW_INTERVENTION = "plenary_new_intervention";
export const MINUTES_MANAGER__INTERVENTION = "minutes_manager__intervention";
export const MINUTES_MANAGER__ADD_INTERVENTION = "minutes_manager__add_intervention";
export const MINUTES_MANAGER__START = "minutes_manager__start";
export const MINUTES_MANAGER__START_TIME = "minutes_manager__start_time";
export const MINUTES_MANAGER__END = "minutes_manager__end";
export const MINUTES_MANAGER__DELETE_MODAL__TITLE = "minutes_manager__delete_modal__title";
export const MINUTES_MANAGER__DELETE_MODAL__TEXT = "minutes_manager__delete_modal__text";

//TRANSCRIPCIONES
export const PLENARY_SESSIONS__TRANSCRIPTIONS = "plenary_sessions__transcriptions";
export const SET_TIME = "set_time";
export const MESSAGE_VALIDATION_LANGUAGE_FIELD = "message_validation_language_field";
export const MESSAGE_VALIDATION_ORGANIZATION_FIELD = "message_validation_organization_field";
export const MESSAGE_VALIDATION_NAME_FIELD = "message_validation_name_field";
export const MESSAGE_VALIDATION_DESCRIPTION_FIELD = "message_validation_description_field";
export const TOOLTIP_FIN = "tooltip_fin";
export const TOOLTIP_INICIO = "tooltip_inicio";
export const TOOLTIP_PLENARY_NOT_STARTED = "tooltip_plenary_not_started";
export const MESSAGE_VALIDATION_NOT_TRANSCRIPTIONS = "message_validation_not_transitions";
export const MESSAGE_VALIDATION_NAME_REALIZATION = "message_validation_description_realization";
export const MESSAGE_VALIDATION_RESERVED_WORDS = "message_validation_reserved_words";

//CREACIÓN PLENO o EDICION PLENO
export const TITLE_MODAL_ERROR_MESSAGE_CREATION_PLENARY = "title_modal_error_message_creation_plenary";
export const MESSAGE_MODAL_ERROR_MESSAGE_CREATION_PLENARY = "message_modal_error_message_creation_plenary";
export const TOOLTIP_MESSAGE_START_DATE_EDITION_PLENARY = "tooltip_message_start_date_edition_plenary";
export const TOOLTIP_MESSAGE_FINISH_DATE_EDITION_PLENARY = "tooltip_message_finish_date_edition_plenary";
export const MESSAGE_URL_VIDEO_CREATION_PLENARY = "message_url_video_creation_plenary";
