import React, { useEffect, useState } from "react";
import { getLanguagesService, updatePlenaryInformation, getSessionInfo } from "../../../../../services/commonservices";
import { hasRoles } from "../../../../../utils/keycloak";
import { useSelector } from "react-redux";
import {
  BUTTON_TYPE_BUTTON,
  BUTTON_TYPE_SUBMIT,
  FALSE,
  I18N_CONFIG,
  INPUT_TYPE_TEXT,
  ROLE_TIIVII_SUPER_USER,
  ROLE_TIIVIIPLEN_ADMIN_USER,
  SESSION_INFORMATION_IS_PRIVATE,
  SESSION_INFORMATION__SUBTITLE__TRANSLATION_TYPE,
  SESSION_INFORMATION__TITLE__TRANSLATION_TYPE,
  TRUE,
  SESSION__PLENARY__STATUS__STARTED,
  SESSION__PLENARY__STATUS__STARTING,
  SESSION__PLENARY__STATUS__ERROR,
  SESSION__PLENARY__STATUS__CREATED,
  SESSION__PLENARY__STATUS__SCHEDULED,
  SESSION__PLENARY__STATUS__IN_REVIEW,
  SESSION__PLENARY__STATUS__REVIEWED,
  SESSION__PLENARY__STATUS__FINISHED,
  SESSION__PLENARY__STATUS__PUBLISHED,
  SESSION_INFORMATION__URL_VIDEO,
  LOCAL_STORAGE_ITEMS,
} from "../../../../../utils/constants";
import { useIntl } from "react-intl";
import {
  ADVANCED_CONFIG,
  ADVANCED_CONFIG_DESCRIPTION,
  BUTTON_DISABLED_BY_SESSION_STATUS,
  BUTTON_DISABLED_BY_USER_PERMISSION,
  COMMON__BACK,
  COMMON__EDIT,
  COMMON__HIDE,
  COMMON__NO,
  COMMON__SHOW,
  COMMON__YES,
  COMMON_CANCEL,
  COMMON_SAVE,
  GO_TO_MIXER,
  GO_TO_MIXER_DESCRIPTION,
  INFORMATION__PLENARY,
  PLENARY__SESSIONS__COMMISSION,
  PLENARY__SESSIONS__COMMISSION_EXTERNAL__URL,
  PLENARY__SESSIONS__END_DATE,
  PLENARY__SESSIONS__INIT_DATE,
  PLENARY__SESSIONS__PRIVATE_SESSION,
  PLENARY__SESSIONS__SITE,
  PLENARY__SESSIONS__STATUS__STATUS,
  PLENARY__SESSIONS__SUBTITLE,
  PLENARY__SESSIONS__TITLE,
  PLENARY_DETAIL__PAGE_TITLE,
  PLENARY_LIST__PAGE_DESCRIPTION,
  PLENARY_LIST__PAGE_TITLE,
  SESSION_INFO_CARD__TITLE,
  SESSION_INFO_ERROR,
  LANGUAGE_REALIZATION_LABELS
} from "../../../../../translations/constans";
import { Paper } from "@material-ui/core";
import EditIcon from "@mui/icons-material/Edit";
import ButtonComponent from "../../../../shared/button/ButtonComponent";
import { BUTTON } from "../../../../shared/button/buttonConstants";
import TypographyComponent from "../../../../shared/typography/TypographyComponent";
import {
  TEXT_VARIANT_H1,
  TEXT_VARIANT_H2,
  TEXT_VARIANT_H3,
  TEXT_VARIANT_P,
} from "../../../../shared/typography/constants";
import style from "./SessionInformation.module.css";
import {
  ArrowBackOutlined,
  SubtitlesOutlined,
  TuneOutlined,
  VisibilityOffOutlined,
  VisibilityOutlined,
} from "@mui/icons-material";
import { plenaryStatusTranslation } from "../../../plenaryList/plenaryListConstants";
import { formatDateTime } from "../../../../../utils/utils";
import Tooltip from "@mui/material/Tooltip";
import { useHistory } from "react-router-dom";
import { ROUTE_PLENARY_LIST, ROUTE_PLENARY_EDIT } from "../../../../../utils/routes";
import { buildUrl } from "../../../../../utils/axiosRequests";
import BACK_ICON from "../../../../../resources/BackIcon";
import styles from "../../../../common/plenaryCreationComponent/PlenaryCreation.module.css";

const SessionInformationComponent = ({ plenarySessionId, onReloadData, editableByStatus }) => {
  const history = useHistory();
  const [isEditing, setIsEditing] = useState(false);
  const [editedData, setEditedData] = useState({});
  const [hideInfo, setHideInfo] = useState(false);
  const availableLanguages = useSelector((state) => state.common.availableLanguages);
  const sessionData = useSelector((state) => state.sessionDetail.sessionDetailData);
  const [defaultLanguage, setDefaultLanguage] = useState(null); // Añadir el estado para el idioma por defecto
  const intl = useIntl();
  const selectedLanguage = useSelector((state) => state.common?.language);
  const handleChangeTranscription = (transcription, type, language) => {
    const newData = { ...editedData };
    if (type === SESSION_INFORMATION__TITLE__TRANSLATION_TYPE) {
      newData.title_translations[language] = transcription;
    } else {
      newData.subtitle_translations[language] = transcription;
    }
    setEditedData(newData);
  };

  const handleChangePrivateSession = (event) => {
    const newData = { ...editedData };
    newData.private_session = event.target.value;
    setEditedData(newData);
  };

  const handleChangeURLVideo = (event) => {
    const newData = { ...editedData };
    newData.vod_url = event.target.value;
    setEditedData(newData);
  };

  const keycloak = useSelector((state) => state.common.keycloak);
  useEffect(() => {
    getLanguagesService(
      (response) => {
        if (response.data && response.data.default_language) {
          setDefaultLanguage(response.data.default_language);
        }
      },
      (error) => {
      }
    );
  }, []);

  useEffect(() => {
    if (sessionData) {
      let updatedSessionData = { ...sessionData };
      if (!sessionData.language && defaultLanguage) {
        updatedSessionData.language = defaultLanguage;
        updatePlenaryInformation(
          plenarySessionId,
          { language: defaultLanguage },
          () => {
          },
          (error) => {
          }
        );
      }

      setEditedData(updatedSessionData);
    }
  }, [sessionData, defaultLanguage]);

  const handleEdit = () => {
    setIsEditing(true);
    history.push(buildUrl(ROUTE_PLENARY_EDIT, { id: plenarySessionId }));
  };

  const handleCancel = () => {
    setIsEditing(false);
    setEditedData(sessionData);
  };

  const handleSave = (event) => {
    event.preventDefault();
    updatePlenaryInformation(plenarySessionId, editedData, onReloadData);
    setIsEditing(false);
  };
  const handleAdvanceConf = (url) => {
    window.open(url, "_blank");
  };
  const handleMixer = (url) => {
    window.open(url, "_blank");
  };
  const handleGoBack = () => {
    history.push(ROUTE_PLENARY_LIST);
  };
  const handleHideInfo = () => {
    setHideInfo(!hideInfo);
  };

  const initDate = sessionData ? new Date(sessionData?.init_date) : null;
  const endDate = sessionData ? new Date(sessionData?.end_date) : null;
  const canEdit = hasRoles(keycloak, [ROLE_TIIVIIPLEN_ADMIN_USER, ROLE_TIIVII_SUPER_USER]);

  return (
    <>
      <div className={style.buttonHeaderContainer}>
        <ButtonComponent
          text={intl.formatMessage({ id: COMMON__BACK })}
          icon={BACK_ICON}
          variant={BUTTON.VARIANT_TEXT}
          onClick={handleGoBack}
        />
        {(sessionData.status === SESSION__PLENARY__STATUS__REVIEWED ||
          sessionData.status === SESSION__PLENARY__STATUS__PUBLISHED ||
          sessionData.status === SESSION__PLENARY__STATUS__ERROR) && (
            <ButtonComponent
              text={intl.formatMessage({ id: `${!hideInfo ? COMMON__HIDE : COMMON__SHOW}` })}
              icon={!hideInfo ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
              variant={BUTTON.VARIANT_TEXT}
              onClick={handleHideInfo}
            />
          )}
      </div>
      <TypographyComponent text={intl.formatMessage({ id: PLENARY_DETAIL__PAGE_TITLE })} variant={TEXT_VARIANT_H1} />
      <TypographyComponent text={sessionData?.title_translations?.[selectedLanguage] || sessionData?.title} variant={TEXT_VARIANT_H3} />
      <Paper
        elevation={4}
        style={{
          margin: "var(--spacing-xl)",
          padding: "var(--spacing-xl)",
          paddingTop: 5,
        }}
      >
        {canEdit && isEditing && (
          <form onSubmit={handleSave}>
            {availableLanguages.map((language) => {
              return (
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "100px 1fr 1fr",
                  }}
                >
                  <div style={{ verticalAlign: "center" }}>
                    <h3>{intl.formatMessage({ id: I18N_CONFIG[language].label })}</h3>
                  </div>
                  <div style={{ padding: "10px 50px" }}>
                    <label>
                      {intl.formatMessage({ id: PLENARY__SESSIONS__TITLE })}:
                      <input
                        style={{
                          border: "1px solid grey",
                          borderRadius: 6,
                          padding: 8,
                        }}
                        type={INPUT_TYPE_TEXT}
                        name={SESSION_INFORMATION__TITLE__TRANSLATION_TYPE}
                        value={editedData?.title_translations?.[language]}
                        onChange={(event) => handleChangeTranscription(event.target.value, event.target.name, language)}
                      />
                    </label>
                  </div>
                  <div style={{ padding: "10px 50px" }}>
                    <label>
                      {intl.formatMessage({ id: PLENARY__SESSIONS__SUBTITLE })}:
                      <input
                        style={{
                          border: "1px solid grey",
                          borderRadius: 6,
                          padding: 8,
                        }}
                        type={INPUT_TYPE_TEXT}
                        name={SESSION_INFORMATION__SUBTITLE__TRANSLATION_TYPE}
                        value={editedData?.subtitle_translations?.[language]}
                        onChange={(event) => handleChangeTranscription(event.target.value, event.target.name, language)}
                      />
                    </label>
                  </div>
                </div>
              );
            })}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <label style={{ width: "50%" }}>
                {intl.formatMessage({ id: PLENARY__SESSIONS__PRIVATE_SESSION })}
                <select
                  name={SESSION_INFORMATION_IS_PRIVATE}
                  value={editedData?.private_session}
                  onChange={handleChangePrivateSession}
                >
                  <option value={TRUE}>{intl.formatMessage({ id: COMMON__YES })}</option>
                  <option value={FALSE}>{intl.formatMessage({ id: COMMON__NO })}</option>
                </select>
              </label>
            </div>
            <div style={{ padding: "10px 50px" }}>
              <label>
                {intl.formatMessage({ id: PLENARY__SESSIONS__COMMISSION_EXTERNAL__URL })}:
                <input
                  style={{
                    border: "1px solid grey",
                    borderRadius: 6,
                    padding: 8,
                  }}
                  type={INPUT_TYPE_TEXT}
                  name={SESSION_INFORMATION__URL_VIDEO}
                  value={editedData?.vod_url}
                  onChange={(event) => handleChangeURLVideo(event.target.value)}
                />
              </label>
            </div>
            <div
              style={{
                display: "flex",
                padding: 20,
                width: "100%",
              }}
            >
              <div
                style={{
                  background: "red",
                  marginRight: 20,
                  width: "100%",
                }}
              >
                <button style={{ width: "100%" }} type={BUTTON_TYPE_BUTTON} onClick={handleCancel}>
                  {intl.formatMessage({ id: COMMON_CANCEL })}
                </button>
              </div>
              <div
                style={{
                  background: "#5fb2f6",
                  width: "100%",
                }}
              >
                <button style={{ width: "100%" }} type={BUTTON_TYPE_SUBMIT}>
                  {intl.formatMessage({ id: COMMON_SAVE })}
                </button>
              </div>
            </div>
          </form>
        )}

        {/* CREATED || SCHEDULED || FINISHED*/}
        {(sessionData.status === SESSION__PLENARY__STATUS__FINISHED ||
          sessionData.status === SESSION__PLENARY__STATUS__CREATED ||
          sessionData.status === SESSION__PLENARY__STATUS__SCHEDULED) && (
            <div className={style.sessionInfoCardContainer}>
              <div className={style.sessionInfoCardDataBlock}>
                <div className={style.sessionInfoCardHeader}>
                  <TypographyComponent
                    text={intl.formatMessage({ id: INFORMATION__PLENARY })}
                    variant={TEXT_VARIANT_H2}
                  />
                  {/*{sessionData.status === SESSION__PLENARY__STATUS__ERROR && (
                <TypographyComponent
                  text={intl.formatMessage({ id: SESSION_INFO_ERROR })}
                  variant={TEXT_VARIANT_H3}
                  style={{ color: "var(--color-danger)" }}
                />
              )}*/}
                </div>
                <div className={style.sessionInfoCardContentRow}>
                  <div>
                    <TypographyComponent
                      text={intl.formatMessage({ id: PLENARY__SESSIONS__TITLE })}
                      variant={TEXT_VARIANT_H3}
                    />
                    <TypographyComponent text={sessionData?.title_translations?.[selectedLanguage] || sessionData?.title} variant={TEXT_VARIANT_P} />
                  </div>
                  <div>
                    <TypographyComponent
                      text={intl.formatMessage({ id: PLENARY__SESSIONS__SUBTITLE })}
                      variant={TEXT_VARIANT_H3}
                    />
                    <TypographyComponent text={sessionData?.subtitle_translations?.[selectedLanguage] || sessionData?.subtitle} variant={TEXT_VARIANT_P} />
                  </div>
                  <div>
                    <TypographyComponent
                      text={intl.formatMessage({ id: LANGUAGE_REALIZATION_LABELS })}
                      variant={TEXT_VARIANT_H3}
                    />
                    <TypographyComponent
                      text={sessionData?.language ? sessionData.language : defaultLanguage}
                      variant={TEXT_VARIANT_P}

                    />
                  </div>
                </div>
                <div className={style.sessionInfoCardContentRow}>
                  <div>
                    <TypographyComponent
                      text={intl.formatMessage({ id: PLENARY__SESSIONS__SITE })}
                      variant={TEXT_VARIANT_H3}
                    />
                    <TypographyComponent text={sessionData?.plenary_room_name} variant={TEXT_VARIANT_P} />
                  </div>
                  <div>
                    <TypographyComponent
                      text={intl.formatMessage({ id: PLENARY__SESSIONS__COMMISSION })}
                      variant={TEXT_VARIANT_H3}
                    />
                    <TypographyComponent text={sessionData?.user_group_name || '-'} variant={TEXT_VARIANT_P} />
                  </div>
                  <div>
                    <TypographyComponent
                      text={intl.formatMessage({ id: PLENARY__SESSIONS__STATUS__STATUS })}
                      variant={TEXT_VARIANT_H3}
                    />
                    <TypographyComponent
                      text={plenaryStatusTranslation(intl, sessionData?.status)}
                      variant={TEXT_VARIANT_P}
                    />
                  </div>
                </div>
                <div className={style.sessionInfoCardContentRow}>
                  <div>
                    <TypographyComponent
                      text={intl.formatMessage({ id: PLENARY__SESSIONS__INIT_DATE })}
                      variant={TEXT_VARIANT_H3}
                    />
                    <TypographyComponent text={formatDateTime(initDate)} variant={TEXT_VARIANT_P} />
                  </div>
                  <div>
                    <TypographyComponent
                      text={intl.formatMessage({ id: PLENARY__SESSIONS__END_DATE })}
                      variant={TEXT_VARIANT_H3}
                    />
                    <TypographyComponent text={formatDateTime(endDate)} variant={TEXT_VARIANT_P} />
                  </div>
                  <div>
                    <TypographyComponent
                      text={intl.formatMessage({ id: PLENARY__SESSIONS__PRIVATE_SESSION })}
                      variant={TEXT_VARIANT_H3}
                    />
                    <TypographyComponent
                      text={
                        sessionData?.private_session
                          ? intl.formatMessage({ id: COMMON__YES })
                          : intl.formatMessage({ id: COMMON__NO })
                      }
                      variant={TEXT_VARIANT_P}
                    />
                  </div>
                </div>
                <div className={style.sessionInfoCardContentRow}>
                  <div>
                    <TypographyComponent
                      text={intl.formatMessage({ id: PLENARY__SESSIONS__COMMISSION_EXTERNAL__URL })}
                      variant={TEXT_VARIANT_H3}
                    />
                    <TypographyComponent text={sessionData.vod_url} variant={TEXT_VARIANT_P} />
                  </div>
                </div>
              </div>
              <div className={style.sessionInfoCardButtonsBlock}>
                <Tooltip
                  title={
                    hasRoles(keycloak, [ROLE_TIIVII_SUPER_USER, ROLE_TIIVIIPLEN_ADMIN_USER])
                      ? editableByStatus
                        ? null
                        : intl.formatMessage({ id: BUTTON_DISABLED_BY_SESSION_STATUS })
                      : intl.formatMessage({ id: BUTTON_DISABLED_BY_USER_PERMISSION })
                  }
                >
                  <div>
                    <ButtonComponent
                      onClick={handleEdit}
                      disabled={
                        !editableByStatus || !hasRoles(keycloak, [ROLE_TIIVII_SUPER_USER, ROLE_TIIVIIPLEN_ADMIN_USER])
                      }
                      variant={editableByStatus ? BUTTON.VARIANT_PRIMARY : BUTTON.VARIANT_DISABLE}
                      text={intl.formatMessage({ id: COMMON__EDIT })}
                      icon={<EditIcon />}
                      size={BUTTON.SIZE__FIT_CONTENT}
                    />
                  </div>
                </Tooltip>
                <Tooltip
                  title={
                    hasRoles(keycloak, [ROLE_TIIVII_SUPER_USER, ROLE_TIIVIIPLEN_ADMIN_USER])
                      ? editableByStatus
                        ? intl.formatMessage({ id: ADVANCED_CONFIG_DESCRIPTION })
                        : intl.formatMessage({ id: BUTTON_DISABLED_BY_SESSION_STATUS })
                      : intl.formatMessage({ id: BUTTON_DISABLED_BY_USER_PERMISSION })
                  }
                >
                  <div>
                    <ButtonComponent
                      disabled={
                        !editableByStatus || !hasRoles(keycloak, [ROLE_TIIVII_SUPER_USER, ROLE_TIIVIIPLEN_ADMIN_USER])
                      }
                      onClick={() => handleAdvanceConf(sessionData.event_url)}
                      variant={
                        !editableByStatus || !hasRoles(keycloak, [ROLE_TIIVII_SUPER_USER, ROLE_TIIVIIPLEN_ADMIN_USER])
                          ? BUTTON.VARIANT_DISABLE
                          : BUTTON.VARIANT_SECONDARY
                      }
                      text={intl.formatMessage({ id: ADVANCED_CONFIG })}
                      icon={<TuneOutlined />}
                      size={BUTTON.SIZE__FIT_CONTENT}
                    />
                  </div>
                </Tooltip>
              </div>
            </div>
          )}

        {/* STARTED || STARTING */}
        {(sessionData.status === SESSION__PLENARY__STATUS__STARTED ||
          sessionData.status === SESSION__PLENARY__STATUS__STARTING ||
          sessionData.status === SESSION__PLENARY__STATUS__IN_REVIEW) && (
            <div className={style[`sessionInfoCardContainer-started`]}>
              <div className={style[`sessionInfoCardHeader-started`]}>
                <TypographyComponent
                  text={intl.formatMessage({ id: SESSION_INFO_CARD__TITLE })}
                  variant={TEXT_VARIANT_H2}
                />
              </div>
              <div className={style[`sessionInfoCardContentRow-started`]}>
                <div>
                  <TypographyComponent
                    text={intl.formatMessage({ id: PLENARY__SESSIONS__TITLE })}
                    variant={TEXT_VARIANT_H3}
                  />
                  <TypographyComponent text={sessionData?.title_translations?.[selectedLanguage] || sessionData?.title} variant={TEXT_VARIANT_P} />
                </div>
                <div>
                  <TypographyComponent
                    text={intl.formatMessage({ id: PLENARY__SESSIONS__SUBTITLE })}
                    variant={TEXT_VARIANT_H3}
                  />
                  <TypographyComponent text={sessionData?.subtitle_translations?.[selectedLanguage] || sessionData?.subtitle} variant={TEXT_VARIANT_P} />
                </div>
                <div>
                  <TypographyComponent
                    text={intl.formatMessage({ id: LANGUAGE_REALIZATION_LABELS })}
                    variant={TEXT_VARIANT_H3}
                  />
                  <TypographyComponent
                    text={sessionData?.language || defaultLanguage}
                    variant={TEXT_VARIANT_P}
                  />
                </div>
                <Tooltip
                  title={
                    hasRoles(keycloak, [ROLE_TIIVII_SUPER_USER, ROLE_TIIVIIPLEN_ADMIN_USER])
                      ? editableByStatus
                        ? null
                        : intl.formatMessage({ id: BUTTON_DISABLED_BY_SESSION_STATUS })
                      : intl.formatMessage({ id: BUTTON_DISABLED_BY_USER_PERMISSION })
                  }
                >
                  <div className={style[`sessionInfoCardItem-right-started`]}>
                    <ButtonComponent
                      onClick={handleEdit}
                      disabled={
                        !editableByStatus || !hasRoles(keycloak, [ROLE_TIIVII_SUPER_USER, ROLE_TIIVIIPLEN_ADMIN_USER])
                      }
                      variant={editableByStatus ? BUTTON.VARIANT_PRIMARY : BUTTON.VARIANT_DISABLE}
                      text={intl.formatMessage({ id: COMMON__EDIT })}
                      icon={<EditIcon />}
                      size={BUTTON.SIZE__FIT_CONTENT}
                    />
                  </div>
                </Tooltip>
              </div>
              <div className={style[`sessionInfoCardContentRow-started`]}>
                <div>
                  <TypographyComponent
                    text={intl.formatMessage({ id: PLENARY__SESSIONS__SITE })}
                    variant={TEXT_VARIANT_H3}
                  />
                  <TypographyComponent text={sessionData?.plenary_room_name} variant={TEXT_VARIANT_P} />
                </div>
                <div>
                  <TypographyComponent
                    text={intl.formatMessage({ id: PLENARY__SESSIONS__COMMISSION })}
                    variant={TEXT_VARIANT_H3}
                  />
                  <TypographyComponent text={sessionData?.user_group_name || '-'} variant={TEXT_VARIANT_P} />
                </div>
                <div>
                  <TypographyComponent
                    text={intl.formatMessage({ id: PLENARY__SESSIONS__STATUS__STATUS })}
                    variant={TEXT_VARIANT_H3}
                    className={style[`sessionInfoCardColoredText-started`]}
                  />
                  <TypographyComponent
                    text={plenaryStatusTranslation(intl, sessionData?.status)}
                    variant={TEXT_VARIANT_P}
                    className={style[`sessionInfoCardColoredText-started`]}
                  />
                </div>
                {(sessionData.status === SESSION__PLENARY__STATUS__STARTED ||
                  sessionData.status === SESSION__PLENARY__STATUS__STARTING) && (
                    <Tooltip
                      title={
                        hasRoles(keycloak, [ROLE_TIIVII_SUPER_USER, ROLE_TIIVIIPLEN_ADMIN_USER])
                          ? editableByStatus
                            ? intl.formatMessage({ id: GO_TO_MIXER_DESCRIPTION })
                            : intl.formatMessage({ id: BUTTON_DISABLED_BY_SESSION_STATUS })
                          : intl.formatMessage({ id: BUTTON_DISABLED_BY_USER_PERMISSION })
                      }
                    >
                      <div className={style[`sessionInfoCardItem-right-started`]}>
                        <ButtonComponent
                          disabled={
                            !editableByStatus || !hasRoles(keycloak, [ROLE_TIIVII_SUPER_USER, ROLE_TIIVIIPLEN_ADMIN_USER])
                          }
                          variant={editableByStatus ? BUTTON.VARIANT_SECONDARY : BUTTON.VARIANT_DISABLE}
                          onClick={() => handleMixer(sessionData.mixer_url)}
                          text={intl.formatMessage({ id: GO_TO_MIXER })}
                          icon={<SubtitlesOutlined />}
                          size={BUTTON.SIZE__FIT_CONTENT}
                        />
                      </div>
                    </Tooltip>
                  )}
              </div>
              <div className={style[`sessionInfoCardContentRow-started`]}>
                <div>
                  <TypographyComponent
                    text={intl.formatMessage({ id: PLENARY__SESSIONS__INIT_DATE })}
                    variant={TEXT_VARIANT_H3}
                  />
                  <TypographyComponent text={formatDateTime(initDate)} variant={TEXT_VARIANT_P} />
                </div>
                <div>
                  <TypographyComponent
                    text={intl.formatMessage({ id: PLENARY__SESSIONS__END_DATE })}
                    variant={TEXT_VARIANT_H3}
                  />
                  <TypographyComponent text={formatDateTime(endDate)} variant={TEXT_VARIANT_P} />
                </div>
                <div>
                  <TypographyComponent
                    text={intl.formatMessage({ id: PLENARY__SESSIONS__PRIVATE_SESSION })}
                    variant={TEXT_VARIANT_H3}
                  />
                  <TypographyComponent
                    text={
                      sessionData?.private_session
                        ? intl.formatMessage({ id: COMMON__YES })
                        : intl.formatMessage({ id: COMMON__NO })
                    }
                    variant={TEXT_VARIANT_P}
                  />
                </div>

                <div className={style[`sessionInfoCardItem-right-started`]}>
                  <Tooltip
                    title={
                      hasRoles(keycloak, [ROLE_TIIVII_SUPER_USER, ROLE_TIIVIIPLEN_ADMIN_USER])
                        ? editableByStatus
                          ? intl.formatMessage({ id: ADVANCED_CONFIG_DESCRIPTION })
                          : intl.formatMessage({ id: BUTTON_DISABLED_BY_SESSION_STATUS })
                        : intl.formatMessage({ id: BUTTON_DISABLED_BY_USER_PERMISSION })
                    }
                  >
                    <div>
                      <ButtonComponent
                        disabled={
                          !editableByStatus || !hasRoles(keycloak, [ROLE_TIIVII_SUPER_USER, ROLE_TIIVIIPLEN_ADMIN_USER])
                        }
                        variant={editableByStatus ? BUTTON.VARIANT_PRIMARY : BUTTON.VARIANT_DISABLE}
                        onClick={() => handleAdvanceConf(sessionData.event_url)}
                        text={intl.formatMessage({ id: ADVANCED_CONFIG })}
                        icon={<TuneOutlined />}
                        size={BUTTON.SIZE__FIT_CONTENT}
                      />
                    </div>
                  </Tooltip>
                </div>
              </div>
            </div>
          )}
        {(sessionData.status === SESSION__PLENARY__STATUS__REVIEWED ||
          sessionData.status === SESSION__PLENARY__STATUS__PUBLISHED ||
          sessionData.status === SESSION__PLENARY__STATUS__ERROR) && (
            <div className={style[`sessionInfoCardContainer-noEdit`]}>
              <div className={style[`sessionInfoCardHeader-noEdit`]}>
                <TypographyComponent
                  text={intl.formatMessage({ id: SESSION_INFO_CARD__TITLE })}
                  variant={TEXT_VARIANT_H2}
                />
                {sessionData.status === SESSION__PLENARY__STATUS__ERROR && (
                  <TypographyComponent
                    text={intl.formatMessage({ id: SESSION_INFO_ERROR })}
                    variant={TEXT_VARIANT_H3}
                    style={{ color: "var(--color-danger)" }}
                  />
                )}
              </div>
              {!hideInfo && (
                <>
                  <div className={style[`sessionInfoCardContentRow-noEdit`]}>
                    <div className={style[`sessionInfoCardItem-noEdit`]}>
                      <TypographyComponent
                        text={intl.formatMessage({ id: PLENARY__SESSIONS__SITE })}
                        variant={TEXT_VARIANT_H3}
                      />
                      {": "}
                      <TypographyComponent text={sessionData?.plenary_room_name} variant={TEXT_VARIANT_P} />
                    </div>
                    <div className={style[`sessionInfoCardItem-noEdit`]}>
                      <TypographyComponent
                        text={intl.formatMessage({ id: PLENARY__SESSIONS__TITLE })}
                        variant={TEXT_VARIANT_H3}
                      />
                      {": "}
                      <TypographyComponent text={sessionData?.title_translations?.[selectedLanguage] || sessionData?.title} variant={TEXT_VARIANT_P} />
                    </div>
                    <div className={style[`sessionInfoCardItem-noEdit`]}>
                      <TypographyComponent
                        text={intl.formatMessage({ id: PLENARY__SESSIONS__INIT_DATE })}
                        variant={TEXT_VARIANT_H3}
                      />
                      {": "}
                      <TypographyComponent text={formatDateTime(initDate)} variant={TEXT_VARIANT_P} />
                    </div>
                    <div className={style[`sessionInfoCardItem-noEdit`]}>
                      <TypographyComponent
                        text={intl.formatMessage({ id: PLENARY__SESSIONS__STATUS__STATUS })}
                        variant={TEXT_VARIANT_H3}
                      />
                      {": "}
                      <TypographyComponent
                        text={plenaryStatusTranslation(intl, sessionData?.status)}
                        variant={TEXT_VARIANT_P}
                        style={{
                          color: `${sessionData.status === SESSION__PLENARY__STATUS__ERROR ? "var(--color-danger)" : null
                            }`,
                        }}
                      />
                    </div>
                  </div>
                  <div className={style[`sessionInfoCardContentRow-noEdit`]}>
                    <div className={style[`sessionInfoCardItem-noEdit`]}>
                      <TypographyComponent
                        text={intl.formatMessage({ id: PLENARY__SESSIONS__COMMISSION })}
                        variant={TEXT_VARIANT_H3}
                      />
                      {": "}
                      <TypographyComponent text={sessionData?.user_group_name || '-'} variant={TEXT_VARIANT_P} />
                    </div>
                    <div className={style[`sessionInfoCardItem-noEdit`]}>
                      <TypographyComponent
                        text={intl.formatMessage({ id: PLENARY__SESSIONS__SUBTITLE })}
                        variant={TEXT_VARIANT_H3}
                      />
                      {": "}
                      <TypographyComponent text={sessionData?.subtitle_translations?.[selectedLanguage] || sessionData?.subtitle} variant={TEXT_VARIANT_P} />
                    </div>
                    <div className={style[`sessionInfoCardItem-noEdit`]}>
                      <TypographyComponent
                        text={intl.formatMessage({ id: PLENARY__SESSIONS__END_DATE })}
                        variant={TEXT_VARIANT_H3}
                      />
                      {": "}
                      <TypographyComponent text={formatDateTime(endDate)} variant={TEXT_VARIANT_P} />
                    </div>
                    <div className={style[`sessionInfoCardItem-noEdit`]}>
                      <TypographyComponent
                        text={intl.formatMessage({ id: PLENARY__SESSIONS__PRIVATE_SESSION })}
                        variant={TEXT_VARIANT_H3}
                      />
                      {": "}
                      <TypographyComponent
                        text={
                          sessionData?.private_session
                            ? intl.formatMessage({ id: COMMON__YES })
                            : intl.formatMessage({ id: COMMON__NO })
                        }
                        variant={TEXT_VARIANT_P}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
          )}
      </Paper>
    </>
  );
};
export default SessionInformationComponent;
