import React, { useState } from "react";
import { useIntl } from "react-intl";
import style from "./AgendaItem.module.css";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import ButtonComponent from "../../../../../../shared/button/ButtonComponent";
import { BUTTON } from "../../../../../../shared/button/buttonConstants";
import TypographyComponent from "../../../../../../shared/typography/TypographyComponent";
import { TEXT_VARIANT_STRONG } from "../../../../../../shared/typography/constants";
import {
  COMMON__DELETE,
  COMMON__EDIT,
  MINUTES_MANAGER__START,
  MINUTES_MANAGER__START_TIME,
  SESSION_AGENDA_MODAL_FINISH_EVENT,
  COMMON_CANCEL,
  COMMON__ACCEPT,
  SESSION_BREAK,
  TOOLTIP_AGENDA_POINTS,
  MESSAGE_ACTIVE_POINT_MODAL
} from "../../../../../../../translations/constans";
import { updateActiveStateAgendaService} from "../../../../../../../services/commonservices";
import {
  LOCAL_STORAGE_ITEMS,
  SESSION__PLENARY__STATUS__STARTED,
} from "../../../../../../../utils/constants";
import clsx from "clsx";
import { useSelector } from "react-redux";
import TimeComponent from "../../../../../../common/time/TimeComponent";
import {Dialog, Modal, Typography} from "@mui/material";
import { DialogTitle, DialogContent, DialogActions } from "@material-ui/core";
import Tooltip from "@mui/material/Tooltip";
import ModalComponent from "../../../../../../shared/modal/ModalComponent";

const AgendaItem = ({
  isSortable,
  isFirst,
  isLast,
  isFinish,
  item,
  onDelete,
  onDownOrder,
  onUpOrder,
  onOpenModal,
  onIsEditing,
  editableByStatus,
  plenarySessionId,
  isActive,
  handleGetAgendaItems,
  timing_from = 0,
  isStartActivated,
  setIsStartActivated,
  startButtonDisabled,
    i
}) => {
  const sessionData = useSelector((state) => state.sessionDetail.sessionDetailData);
  const intl = useIntl();
  const [openFinishDialog, setOpenFinishDialog] = useState(false);
  const userLanguage = localStorage.getItem(LOCAL_STORAGE_ITEMS.TIIVII_PLENOS_LOCALE);
  const default_language = localStorage.getItem(LOCAL_STORAGE_ITEMS.TIIVII_PLENOS_DEFAULT_LANGUAGE);
  const [activePointModal, setActivePointModal] = useState(false);
  const handleDeleteClick = () => {
    onDelete(item.id);
  };

  const handleEditClick = () => {
    onIsEditing(item);
  };

  const callbackActivePoint = () => {
    handleGetAgendaItems();
  };

  const handleEndEvent = () => {
    setOpenFinishDialog(false);
    readyToActIve() &&
      updateActiveStateAgendaService(plenarySessionId, item?.id, callbackActivePoint, callbackActivePoint);
  };

  const handleActivePoint = () => {
    if (item.item_description === intl.formatMessage({ id: MINUTES_MANAGER__START }) && isStartActivated) {
      return;
    }
    if (item.item_description === intl.formatMessage({ id: MINUTES_MANAGER__START })) {
      setIsStartActivated(true);
    }
    if (isFinish && sessionData?.status === SESSION__PLENARY__STATUS__STARTED) {
      setOpenFinishDialog(true);
    }
    if (item?.timing_from > 0 && sessionData?.status === SESSION__PLENARY__STATUS__STARTED) {
      setActivePointModal(true);
    } else {
      readyToActIve() &&
        updateActiveStateAgendaService(plenarySessionId, item?.id, callbackActivePoint, callbackActivePoint);
    }
  };

  const handleCloseFinishDialog = () => {
    setOpenFinishDialog(false);
  };

  const readyToActIve = () => {
    return sessionData?.status === SESSION__PLENARY__STATUS__STARTED;
  };
  const showOrder = (order) => {
    if (order > -1 && order < 99999999) {
      return (
        <div className={style.orderPosition}>
          <TypographyComponent text={order} variant={TEXT_VARIANT_STRONG} />
        </div>
      );
    }
    return <div className={style.orderPosition}></div>;
  };
  const showPoints = (default_language) => {
    const translations = item.item_description_translations;
    if (Object.prototype.hasOwnProperty.call(translations, userLanguage)) {
      if (translations[userLanguage] !== null && translations[userLanguage] !== "") {
        return translations[userLanguage];
      }
    } else {
      return translations?.[default_language] || item?.item_description;
    }
  };

  const isBreakItem = item.item_description === intl.formatMessage({ id: SESSION_BREAK });

  const handleCloseActivePointModal = () => {
    setActivePointModal(false);
  };
  const handleAcceptActivePointModal = () => {
    setActivePointModal(false);
    updateActiveStateAgendaService(plenarySessionId, item?.id, callbackActivePoint, callbackActivePoint);
  }

  return (
    <div
      className={`${style.agendaItemContainer} ${!isSortable && style.agendaItemContainerWithoutSort} 
    `}
    >
      {showOrder(item.order)}
      {isSortable && (
        <div className={style.upDownButtonsContainer}>
          <>
            <button aria-label="Move up" onClick={() => onDownOrder(item.id)} disabled={isFirst}>
              <KeyboardArrowUp />
            </button>
            <button aria-label="Move down" onClick={() => onUpOrder(item.id)} disabled={isLast}>
              <KeyboardArrowDown />
            </button>
          </>
        </div>
      )}
      <Tooltip
          title={
            !startButtonDisabled && sessionData?.status === SESSION__PLENARY__STATUS__STARTED ? intl.formatMessage({ id: TOOLTIP_AGENDA_POINTS }) : ""
          }
          arrow
      >
        <div
          className={clsx(style.agendaItemTextContainer, {
            [style.agendaItemPointer]: (readyToActIve() && !(item.item_description === intl.formatMessage({ id: MINUTES_MANAGER__START }) && isStartActivated)),
            [style.disabledPointer]: !startButtonDisabled || (item.item_description === intl.formatMessage({ id: MINUTES_MANAGER__START }) && isStartActivated),
          })}
          onClick={startButtonDisabled ? handleActivePoint : () => {}}
        >
          {timing_from > 0 && (
            <div className={style.startTime}>
              <TimeComponent
                timeMilliseconds={timing_from}
                timeText={intl.formatMessage({ id: MINUTES_MANAGER__START_TIME })}
              />
            </div>
          )}
          <div>
            <TypographyComponent text={showPoints(default_language)} variant={TEXT_VARIANT_STRONG} />
          </div>
        </div>
      </Tooltip>
      {!isBreakItem && item.editable && (
        <div className={style.agendaItemEditDeleteButtonsContainer}>
          <ButtonComponent
            disabled={!editableByStatus}
            onClick={handleDeleteClick}
            variant={editableByStatus ? BUTTON.VARIANT_TEXT : BUTTON.VARIANT_TEXT_DISABLE}
            text={intl.formatMessage({ id: COMMON__DELETE })}
            size={BUTTON.SIZE__FIT_CONTENT}
            style={{ color: `${editableByStatus ? "blue" : "var(--color-text)"}` }}
          />
          <ButtonComponent
            disabled={!editableByStatus}
            onClick={handleEditClick}
            variant={editableByStatus ? BUTTON.VARIANT_TEXT : BUTTON.VARIANT_TEXT_DISABLE}
            text={intl.formatMessage({ id: COMMON__EDIT })}
            size={BUTTON.SIZE__FIT_CONTENT}
          />
        </div>
      )}
      <Dialog open={openFinishDialog} onClose={handleCloseFinishDialog}>
        <DialogTitle>{intl.formatMessage({ id: SESSION_AGENDA_MODAL_FINISH_EVENT })}</DialogTitle>
        <DialogContent>{ }</DialogContent>
        <DialogActions>
          <ButtonComponent
            color="primary"
            onClick={handleCloseFinishDialog}
            size={BUTTON.SIZE__FIT_CONTENT}
            text={intl.formatMessage({ id: COMMON_CANCEL })}
            variant={BUTTON.VARIANT_SECONDARY}
          />
          <ButtonComponent
            color="primary"
            onClick={handleEndEvent}
            size={BUTTON.SIZE__FIT_CONTENT}
            text={intl.formatMessage({ id: COMMON__ACCEPT })}
            variant={BUTTON.VARIANT_SECONDARY}
          />
        </DialogActions>
      </Dialog>
      <div>
        <Modal
            open={activePointModal}
            onClose={handleCloseActivePointModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
          <ModalComponent
              handleClose={handleCloseActivePointModal}
              title={null}
              body={
                <Typography
                    dangerouslySetInnerHTML={{
                      __html: intl.formatMessage(
                          { id: MESSAGE_ACTIVE_POINT_MODAL },
                          { punto: "<b>" + item.item_description + "</b>" }
                      )
                    }}
                />
              }
              buttonAcceptText={intl.formatMessage({ id: COMMON__ACCEPT })}
              buttonCancelText={intl.formatMessage({ id: COMMON_CANCEL })}
              handleSave={handleAcceptActivePointModal}
              onCancel={handleCloseActivePointModal} // Cierra la modal al cancelar
          />
        </Modal>
      </div>
    </div>
  );
};

export default AgendaItem;
