import React from "react";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@mui/material";
import TypographyComponent from "../../shared/typography/TypographyComponent";
import {
  PLENARY_LIST__FILTER_SELECT_PLACE,
  PLENARY_LIST__FILTER_SELECT_STATUS_ALL,
  SELECT__ROOM,
} from "../../../translations/constans";
import { useIntl } from "react-intl";
import { makeStyles } from "@mui/styles";
import style from "./RoomComponent.module.css";
import { TEXT_VARIANT_P } from "../../shared/typography/constants";
import {
  storeSelectedRoomCreationActionCreator,
  storeSelectedRoomFilterActionCreator,
} from "../../../reducers/commonState";
import {useDispatch, useSelector} from "react-redux";

const useStyles = makeStyles(() => ({
  auto: {
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
      padding: "5px",
    },
    "& .MuiAutocomplete-inputRoot": {
      fontFamily: "var(--font-family-titles) !important",
    },
  },
}));
const RoomsComponent = ({ selectedRoom, infoPlenary = null, creationPlenary }) => {
  const classes = useStyles();
  const intl = useIntl();
  const dispatch = useDispatch();
  const rooms = useSelector((state) => state?.common?.rooms);
  const selectedRoomFilter = useSelector((state) => state?.common.selectedRoomFilter);

  const handleSelectionRooms = (value) => {
    if (selectedRoom === selectedRoomFilter) {
      dispatch(storeSelectedRoomFilterActionCreator(value));
    } else {
      dispatch(storeSelectedRoomCreationActionCreator(value));
    }
  };

  return (
    <div>
      {creationPlenary && (
        <TypographyComponent
          text={intl.formatMessage({ id: PLENARY_LIST__FILTER_SELECT_PLACE })}
          variant={TEXT_VARIANT_P}
        />
      )}
      {rooms?.length === 1 ? (
        <div className={style.textRoomField}>{rooms[0]?.name}</div>
      ) : (
        <div>
          <Autocomplete
            options={rooms}
            value={selectedRoom}
            getOptionLabel={(option) => option?.name}
            onChange={(event, v) => handleSelectionRooms(v)}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={
                  creationPlenary
                    ? intl.formatMessage({ id: SELECT__ROOM })
                    : intl.formatMessage({ id: PLENARY_LIST__FILTER_SELECT_STATUS_ALL })
                }
                className={classes.auto}
              />
            )}
          />
        </div>
      )}
    </div>
  );
};

export default RoomsComponent;
