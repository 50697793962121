import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  language: navigator.language.split(/[-_]/)[0],
  availableLanguages: [],
  loading: false,
  keycloak: null,
  rooms: [],
  selectedRoomFilter: null,
  selectedRoomCreation: null,
  defaultLanguage: "es",
  interventionsOfPoints: true,
};

export const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    setDefaultLanguage: (state, action) => {
      state.defaultLanguage = action.payload;
    },
    changeLanguage: (state, action) => {
      state.language = action.payload;
    },
    changeAvailableLanguages: (state, action) => {
      state.availableLanguages = action.payload;
    },
    turnOffLoadingActionCreator: (state) => {
      state.loading = false;
    },
    turnOnLoadingActionCreator: (state) => {
      state.loading = true;
    },
    storeKeycloakActionCreator: (state, action) => {
      state.keycloak = action.payload;
    },
    storeRoomsActionCreator: (state, action) => {
      state.rooms = action.payload;
    },
    storeSelectedRoomFilterActionCreator: (state, action) => {
      state.selectedRoomFilter = action.payload;
    },
    storeSelectedRoomCreationActionCreator: (state, action) => {
      state.selectedRoomCreation = action.payload;
    },
  },
});

export const {
  setDefaultLanguage,
  changeLanguage,
  changeAvailableLanguages,
  turnOffLoadingActionCreator,
  turnOnLoadingActionCreator,
  storeKeycloakActionCreator,
  storeRoomsActionCreator,
  storeSelectedRoomFilterActionCreator,
  storeSelectedRoomCreationActionCreator,
} = commonSlice.actions;

export const { language, languages, loading, keycloak, rooms, selectedRoom, defaultLanguage } = commonSlice.reducer;

export const selectCurrentLanguage = (state) => state.common.language;

export default commonSlice.reducer;
