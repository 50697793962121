import React, { useEffect, useState } from "react";
import { ThemeProvider } from "@mui/material/styles";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { IntlProvider } from "react-intl";

import "./App.css";
import {
  ROUTE_DESIGN,
  ROUTE_PLENARY_LIST,
  ROUTE_PLENARY_CREATION,
  ROUTE_PLENARY_DETAIL,
  ROUTE_PLENARY_EDIT,
} from "./utils/routes";
import ErrorBoundary from "./components/common/errorBoundaryComponent/ErrorBoundaryComponent";
import ErrorComponent from "./components/common/ErrorComponent";
import NotificationSnack from "./components/common/notificationSnackComponent/NotificationSnackComponent";
import GlobalLoadingComponent from "./components/common/loading/GlobalLoadingComponent";
import TopMenuComponent from "./components/landing/topMenuComponent/TopMenuComponent";
import messagesCa from "./translations/ca.json";
import messagesEs from "./translations/es.json";
import messagesEn from "./translations/en.json";
import messagesGl from "./translations/gl.json";
import FooterComponent from "./components/landing/footerComponent/FooterComponent";
import { COMMON__ERROR_404 } from "./translations/constans";
import {
  APP_TENANT,
  ASTERISK,
  CLASSNAME_APP,
  KEYCLOAK_CLIENT_ID,
  KEYCLOAK_URL,
  LANDING_ERROR_BOUNDARY,
  LANGUAGES_ES,
  LOCAL_STORAGE_ITEMS,
  TENANT_MENORCA,
  TENANT_TIIVII,
  TENANT_TORREVIEJA,
  TENANT_CIUTADELLA,
  TENANT_DEFAULT,
  TENANT_ALAIOR,
  TENANT_ESCASTELL,
  TENANT_FERRERIES,
  TENANT_CONSELL,
} from "./utils/constants";
import { language } from "./utils/utils";
import PageContainer from "./components/common/pageContainer/PageContainer";
import DesignComponent from "./pages/design/DesignComponent";
import { getTenantTheme } from "./customization/utils";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import { useDispatch, useSelector } from "react-redux";
import { getLanguagesService, getRealmService } from "./services/commonservices";
import Keycloak from "keycloak-js";
import {
  changeAvailableLanguages,
  changeLanguage,
  setDefaultLanguage,
  storeKeycloakActionCreator,
} from "./reducers/commonState";
import PlenaryListComponent from "./components/pages/plenaryList/PlenaryListComponent";
import PlenaryDetailComponent from "./components/pages/plenaryDetail/PlenaryDetailComponent";
import PlenaryComponent from "./components/pages/plenaryComponent/PlenaryComponent";

const TENANTS_AVAILABLE = [
  TENANT_TIIVII,
  TENANT_MENORCA,
  TENANT_TORREVIEJA,
  TENANT_CIUTADELLA,
  TENANT_DEFAULT,
  TENANT_ALAIOR,
  TENANT_ESCASTELL,
  TENANT_FERRERIES,
  TENANT_CONSELL,
];

const tenantExists = TENANTS_AVAILABLE.includes(APP_TENANT);

if (tenantExists) {
  import(`./customization/tenants/styles/${APP_TENANT}.css`);
} else {
  import(`./customization/tenants/styles/${TENANT_TIIVII}.css`);
}

const messages = {
  ca: messagesCa,
  es: messagesEs,
  en: messagesEn,
  gl: messagesGl,
};

const App = () => {
  const dispatch = useDispatch();

  const keycloak = useSelector((state) => state.common.keycloak);

  const [locale, setLocale] = useState({
    locale: localStorage.getItem(LOCAL_STORAGE_ITEMS.TIIVII_PLENOS_LOCALE) || language,
    messages: messages[localStorage.getItem(LOCAL_STORAGE_ITEMS.TIIVII_PLENOS_LOCALE) || language]
  });
  const [render, setRender] = useState(false);
  const localLanguage = localStorage.getItem(LOCAL_STORAGE_ITEMS.TIIVII_PLENOS_LOCALE);

  const handleLanguageChanged = (lang) => {
    if (!!messages[lang]) {
      setLocale({ locale: lang, messages: messages[lang] });
      dispatch(changeLanguage(lang));
      localStorage.setItem(LOCAL_STORAGE_ITEMS.TIIVII_PLENOS_LOCALE, lang);
    }
  };

  const realmCallback = (res) => {
    const init = {
      url: KEYCLOAK_URL,
      realm: res.data.realm,
      clientId: KEYCLOAK_CLIENT_ID,
    };

    dispatch(storeKeycloakActionCreator(new Keycloak(init)));
    setRender(true);
  };

  const realmErrorCallback = () => {};

  const languagesCallback = (res) => {
    const { available_languages, default_language } = res.data;
    dispatch(changeAvailableLanguages(available_languages));
    if (localLanguage) {
      dispatch(changeLanguage(localLanguage));
      setLocale({ locale: localLanguage, messages: messages[localLanguage] });
    } else {
      dispatch(changeLanguage(default_language));
      localStorage.setItem(LOCAL_STORAGE_ITEMS.TIIVII_PLENOS_LOCALE, default_language);
      setLocale({ locale: default_language, messages: messages[default_language] });
    }
    dispatch(setDefaultLanguage(default_language));
    localStorage.setItem(LOCAL_STORAGE_ITEMS.TIIVII_PLENOS_DEFAULT_LANGUAGE, default_language);
  };

  const languagesErrorCallback = () => {};

  useEffect(() => {
    getRealmService(realmCallback, realmErrorCallback);
    getLanguagesService(languagesCallback, languagesErrorCallback);
  }, []);

  return (
    <>
      {!!render ? (
        <ReactKeycloakProvider authClient={keycloak}>
          <IntlProvider defaultLocale={locale.locale} locale={locale.locale} messages={locale.messages}>
            <ThemeProvider theme={getTenantTheme()}>
              <div className={CLASSNAME_APP} id={tenantExists ? APP_TENANT : TENANT_TIIVII}>
                <ErrorBoundary key={LANDING_ERROR_BOUNDARY}>
                  <Router basename={process.env.REACT_APP_BASE_ROUTE}>
                    <TopMenuComponent handleLanguageChanged={handleLanguageChanged} />
                    <Switch>
                      <Route exact path={ROUTE_PLENARY_LIST}>
                        <PageContainer component={<PlenaryListComponent />} />
                      </Route>
                      <Route exact path={ROUTE_PLENARY_CREATION}>
                        <PageContainer component={<PlenaryComponent creationPlenary={true} />} />
                      </Route>
                      <Route exact path={ROUTE_PLENARY_EDIT}>
                        <PageContainer component={<PlenaryComponent creationPlenary={false} />} />
                      </Route>
                      <Route exact path={ROUTE_PLENARY_DETAIL}>
                        <PageContainer component={<PlenaryDetailComponent />} />
                      </Route>
                      <Route path={ROUTE_DESIGN}>
                        <DesignComponent />
                      </Route>
                      <Route path={ASTERISK}>
                        <PageContainer component={<ErrorComponent error={COMMON__ERROR_404} />} />
                      </Route>
                    </Switch>
                    <FooterComponent />
                  </Router>
                </ErrorBoundary>
                <NotificationSnack />
                <GlobalLoadingComponent />
              </div>
            </ThemeProvider>
          </IntlProvider>
        </ReactKeycloakProvider>
      ) : (
        <div></div>
      )}
    </>
  );
};

export default App;
