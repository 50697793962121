import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  sessionDetailData: null,
  participants: []
};

export const sessionSlice = createSlice({
  name: "sessionDetail",
  initialState,
  reducers: {
    clearSessionDetailDataActionCreator: (state, action) => {
      state.sessionDetailData = null;
      state.participants = [];
    },
    updateSessionDetailDataActionCreator: (state, action) => {
      state.sessionDetailData = action.payload;
      state.participants = action.payload.people;
    },
    updateAgendaSessionDetailDataActionCreator: (state, action) => {
      state.sessionDetailData.agenda = action.payload;
    },
    updatePeopleActionCreator: (state, action) => {
      state.participants = action.payload.people;
    },
    createParticipantActionCreator: (state, action) => {
      state.participants = [...state.participants, action.payload];
    },
    addParticipantsActionCreator: (state, action) => {
      const newParticipants = action.payload.filter(
        (newParticipant) =>
          newParticipant.member_id !== null &&
          !state.participants.some((existingParticipant) => existingParticipant.member_id === newParticipant.member_id),
      );
      state.participants = state.participants.concat(newParticipants);
    },
    updateParticipantMicroActionCreator: (state, action) => {
      const { participantId, seat_id, caption_enabled } = action.payload;
      const participantIndex = state.participants.findIndex((participant) => participant.id === participantId);
      if (participantIndex !== -1) {
        state.participants[participantIndex].seat_id = seat_id;
        state.participants[participantIndex].caption_enabled = caption_enabled;
      }
    },
    updateParticipantMicroActionCloseCreator: (state, action) => {
      const { participantId, seat_id, close_up} = action.payload;
      const participantIndex = state.participants.findIndex((participant) => participant.id === participantId);
      if (participantIndex !== -1) {
        state.participants[participantIndex].seat_id = seat_id;
        state.participants[participantIndex].close_up = close_up;
      }
    },
    updateParticipantActionCreator: (state, action) => {
      const participantIndex = state.participants.findIndex((participant) => {
        if (participant.member_id === null) {
          return participant.id === action.payload.id;
        } else {
          return participant.member_id === action.payload.member_id;
        }
      });
      if (participantIndex !== -1) {
        state.participants[participantIndex] = action.payload;
      }
    },
    deleteParticipantOfPlenaryActionCreator: (state, action) => {
      state.participants = state.participants.filter((participant) => participant.id !== action.payload);
    },
  },
});

export const {
  addParticipantsActionCreator,
  updatePeopleActionCreator,
  createParticipantActionCreator,
  deleteParticipantOfPlenaryActionCreator,
  updateParticipantActionCreator,
  updateParticipantMicroActionCreator,
  updateSessionDetailDataActionCreator,
  updateParticipantMicroActionCloseCreator,
  clearSessionDetailDataActionCreator,
  updateAgendaSessionDetailDataActionCreator,
} = sessionSlice.actions;

export const { sessionDetailData, participants } = sessionSlice.reducer;

export default sessionSlice.reducer;
