import React, {useEffect, useState} from "react";
import TranscriptionAssignerComponent from "./components/transcriptionAssignerComponent/TranscriptionAssignerComponent";
import TranscriptionEditorComponent from "./components/transcriptionEditorComponent/TranscriptionEditorComponent";
import { Paper } from "@mui/material";
import {
  SESSION__PLENARY__STATUS__CREATED,
  SESSION__PLENARY__STATUS__IN_REVIEW,
  SESSION__PLENARY__STATUS__PUBLISHED,
  SESSION__PLENARY__STATUS__REVIEWED,
  SESSION__PLENARY__STATUS__SCHEDULED,
  SESSION__PLENARY__STATUS__STARTED,
  SESSION__PLENARY__STATUS__STARTING,
} from "../../../../../utils/constants";
import { BUTTON } from "../../../../shared/button/buttonConstants";
import {getAllowedService} from "../../../../../services/commonservices";
import TypographyComponent from "../../../../shared/typography/TypographyComponent";
import {useIntl} from "react-intl";
import {MESSAGE_VALIDATION_NOT_TRANSCRIPTIONS} from "../../../../../translations/constans";
import style from "./PlenaryDetailMinuteTranscriptionComponent.module.css";

const PlenaryDetailMinuteTranscriptionComponent = ({
  plenarySessionId,
  videoRef,
  onReloadData,
  editableByStatus,
  sessionData,
  pointsOfTheDay,
  setPointsOfTheDay,
  interventionsOfPoints,
  setInterventionsOfPoints,
  reloadPointsAndMinutes,
  setReloadPointsAndMinutes,
  reloadInterventionsAndMinutes,
  setReloadInterventionsAndMinutes,
}) => {
  const intl = useIntl();

  const [transcriptionEnabled, setTranscriptionEnabled] = useState(null);
  const allowedCallback = (response) => {
    setTranscriptionEnabled(response.data.transcription_enabled)
  };
  useEffect(() => {
    getAllowedService(allowedCallback);
  }, []);

  const getButtonVariant = (editableByStatus, sessionData) => {
    if (
      editableByStatus &&
      sessionData?.status !== SESSION__PLENARY__STATUS__REVIEWED &&
      sessionData?.status !== SESSION__PLENARY__STATUS__PUBLISHED
    ) {
      return BUTTON.VARIANT_PRIMARY;
    } else {
      return BUTTON.VARIANT_DISABLE;
    }
  };

  const isReviewedPublished = (sessionData) => {
    if (
      sessionData?.status === SESSION__PLENARY__STATUS__REVIEWED ||
      sessionData?.status === SESSION__PLENARY__STATUS__PUBLISHED
    ) {
      return true;
    } else {
      return false;
    }
  };

  const canCreatePoint = (sessionData) => {
    return (
      sessionData?.status === SESSION__PLENARY__STATUS__IN_REVIEW ||
      sessionData?.status === SESSION__PLENARY__STATUS__SCHEDULED ||
      sessionData?.status === SESSION__PLENARY__STATUS__CREATED ||
      sessionData?.status === SESSION__PLENARY__STATUS__STARTING ||
      sessionData?.status === SESSION__PLENARY__STATUS__STARTED
    );
  };

  const buttonDisabled = getButtonVariant(editableByStatus, sessionData);
  const statusReviewedPublished = isReviewedPublished(sessionData);
  const statusCanCreatePoint = canCreatePoint(sessionData);
  return (
      <Paper
          elevation={4}
          style={{
            display: "grid",
            gridTemplateColumns: "2fr 1fr",
            paddingLeft: 32,
            paddingRight: 32,
            margin: 32,
            columnGap: 32,
          }}
      >
        <TranscriptionAssignerComponent
            plenarySessionId={plenarySessionId}
            plenaryTitle={sessionData.title}
            videoRef={videoRef}
            onReloadData={onReloadData}
            editableByStatus={editableByStatus}
            buttonDisabled={buttonDisabled}
            statusReviewedPublished={statusReviewedPublished}
            pointsOfTheDay={pointsOfTheDay}
            setPointsOfTheDay={setPointsOfTheDay}
            interventionsOfPoints={interventionsOfPoints}
            setInterventionsOfPoints={setInterventionsOfPoints}
            canCreatePoint={statusCanCreatePoint}
            reloadPointsAndMinutes={reloadPointsAndMinutes}
            setReloadPointsAndMinutes={setReloadPointsAndMinutes}
            reloadInterventionsAndMinutes={reloadInterventionsAndMinutes}
            setReloadInterventionsAndMinutes={setReloadInterventionsAndMinutes}
            transcriptionEnabled={transcriptionEnabled}
        />
        <div style={{ position: "relative" }}>
          <TranscriptionEditorComponent
              videoRef={videoRef}
              plenarySessionId={plenarySessionId}
              editableByStatus={editableByStatus}
              buttonDisabled={buttonDisabled}
              statusReviewedPublished={statusReviewedPublished}
              transcriptionEnabled={transcriptionEnabled}
          />
          {!transcriptionEnabled && (
              <div className={style.layerTranscriptions}>
                <TypographyComponent text={intl.formatMessage({ id: MESSAGE_VALIDATION_NOT_TRANSCRIPTIONS })} className={style.layerText} />
              </div>
          )}
        </div>
      </Paper>
  );
};

export default PlenaryDetailMinuteTranscriptionComponent;
