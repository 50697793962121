import React, { useState } from "react";
import ButtonComponent from "../../../../../../../../../shared/button/ButtonComponent";
import { useIntl } from "react-intl";
import { CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { BUTTON } from "../../../../../../../../../shared/button/buttonConstants";
import {
  BUTTON_DELETE_PLENARY_DESCRIPTION,
  COMMON__BACK,
  COMMON__DELETE,
  COMMON__GLOBAL_BUTTONS__CLOSE,
  PLENARY__LIST__DELETE_QUESTION, PLENARY_LIST__FILTER_SELECT_PLACE,
} from "../../../../../../../../../../translations/constans";
import { deleteSessionService } from "../../../../../../../../../../services/commonservices";
import { useSelector } from "react-redux";
import { hasRoles } from "../../../../../../../../../../utils/keycloak";
import { ROLE_TIIVII_SUPER_USER, ROLE_TIIVIIPLEN_ADMIN_USER } from "../../../../../../../../../../utils/constants";
import Tooltip from "@mui/material/Tooltip";
import style from './PlenaryListModal.module.css';

export default function PlenaryListModalComponent({ plenarySessionId, onReloadData }) {
  const intl = useIntl();
  const [open, setOpen] = useState(false);
  const keycloak = useSelector((state) => state.common.keycloak);
  const notDisabled = hasRoles(keycloak, [ROLE_TIIVII_SUPER_USER, ROLE_TIIVIIPLEN_ADMIN_USER]);
  const [isDeleted, setIsDeleted] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setIsDeleted(false);
  };

  const handleDelete = () => {
    setIsDeleted(true);
    deleteSessionService(plenarySessionId, onReloadData);
  };

  return (
    <>
      <Tooltip title={!notDisabled ? intl.formatMessage({ id: BUTTON_DELETE_PLENARY_DESCRIPTION }) : ""}>
        <div>
          <ButtonComponent
            color="primary"
            onClick={handleClickOpen}
            size={BUTTON.SIZE__FIT_CONTENT}
            text={intl.formatMessage({ id: COMMON__DELETE })}
            disabled={!notDisabled}
            variant={!notDisabled ? BUTTON.VARIANT_DISABLE : BUTTON.VARIANT_SECONDARY}
          />
        </div>
      </Tooltip>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{intl.formatMessage({ id: PLENARY__LIST__DELETE_QUESTION })}</DialogTitle>
        <DialogContent className={style.dialogContent}>
          {isDeleted && (
              <CircularProgress color="secondary" className={style.circularProgress}/>
          )}
          {/*<Typography>{intl.formatMessage({ id: PLENARY__LIST__DELETE_QUESTION })}</Typography>*/}
        </DialogContent>
        <DialogActions>
          <ButtonComponent
            color="primary"
            onClick={handleClose}
            size={BUTTON.SIZE__FIT_CONTENT}
            text={intl.formatMessage({ id: COMMON__GLOBAL_BUTTONS__CLOSE })}
            variant={BUTTON.VARIANT_SECONDARY}
          />
          <ButtonComponent
            color="primary"
            onClick={handleDelete}
            size={BUTTON.SIZE__FIT_CONTENT}
            text={intl.formatMessage({ id: COMMON__DELETE })}
            variant={BUTTON.VARIANT_SECONDARY}
          />
        </DialogActions>
      </Dialog>
    </>
  );
}
