import React, { useEffect, useRef, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import OffsetInitTimeComponent from "./offsetInitTimeComponent/OffsetInitTimeComponent";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIntervention from "../editIntervention/EditIntervention";
import {
  assignTranscriptionService,
  deleteIntervention,
  getMinutesService,
} from "../../../../../../../services/commonservices";
import style from "./TranscriptionAssingerStyles.module.css";
import {
  BUTTON_DISABLED_BY_SESSION_STATUS,
  COMMON_CANCEL,
  COMMON__DELETE,
  MINUTES_MANAGER__DELETE_MODAL__TEXT,
  MINUTES_MANAGER__DELETE_MODAL__TITLE,
  PLENARY__SESSIONS__ASSIGN_TRANSCRIPTS,
  REORDER_BUTTON_ID,
  TOOLTIP_REORDER_BUTTON_DISABLED,
  VOTE_RESULT_ABSTENTION,
  VOTE_RESULT_NO,
  VOTE_RESULT_YES, MESSAGE_VALIDATION_NOT_TRANSCRIPTIONS,
} from "../../../../../../../translations/constans";
import { useIntl } from "react-intl";
import CreateIntervention from "../createIntervention/CreateIntervention";
import { TEXT_VARIANT_H2, TEXT_VARIANT_P, TEXT_VARIANT_SPAN } from "../../../../../../shared/typography/constants";
import TypographyComponent from "../../../../../../shared/typography/TypographyComponent";
import ButtonComponent from "../../../../../../shared/button/ButtonComponent";
import { BUTTON } from "../../../../../../shared/button/buttonConstants";
import ICON_REFRESH from "../../../../../../../resources/IconRefresh";
import Tooltip from "@mui/material/Tooltip";
import { INTERVENTION_TYPE_VOTE } from "../../../../../../../utils/constants";
import VotingModalComponent from "../../../inDirectComponent/components/orderAreaComponent/components/participantsComponent/componentes/votingModalComponent/VotingModalComponent";
import { CancelOutlined, CheckCircleOutline } from "@mui/icons-material";

const ModalStyle = {
  modalContainer: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "400",
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "var(--border-radius)",
    p: 4,
  },
  textContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
  },
  buttonsContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    gap: "var(--spacing-md)",
    marginTop: "var(--spacing-xl)",
  },
};

const TranscriptionAssignerComponent = ({
  plenarySessionId,
  plenaryTitle = "",
  videoRef,
  editableByStatus,
  buttonDisabled,
  statusReviewedPublished,
  interventionsOfPoints,
  setInterventionsOfPoints,
  canCreatePoint,
  reloadPointsAndMinutes,
  setReloadPointsAndMinutes,
  reloadInterventionsAndMinutes,
  setReloadInterventionsAndMinutes,
    transcriptionEnabled,
}) => {
  const intl = useIntl();
  const [expanded, setExpanded] = React.useState([]);
  const [dayPoints, setDayPoints] = useState([]);
  const [activeState, setActiveState] = useState({
    activeAccordion: null,
    activeLi: null,
  });
  const [createIntervention, setCreateIntervention] = useState(null);
  const [minutes, setMinutes] = useState(null);
  const [backgroundColor, setBackgroundColor] = useState("white");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [hasChangedMap, setHasChangedMap] = useState({});

const accordionRefs = useRef({});

const scrollToAccordion = (accordionId) => {
if (accordionRefs.current[accordionId]) {
accordionRefs.current[accordionId].scrollIntoView({ behavior: "smooth", block: "start" });
}
};

  const handleChange = (panel, isIconClick) => (event, isExpanded) => {
    if (isIconClick) {
const newExpanded = isExpanded
? [...expanded, panel]
: expanded.filter((item) => item !== panel);
setExpanded(newExpanded);
if (isExpanded) {
scrollToAccordion(panel);
}
}
};

  const disableHihglight = useRef(false);

  const reorderInterventionsByTimingFrom = (interventions, lastInTheEnd = false) => {
    const orderedInterventions = interventions.slice();
    orderedInterventions.sort((a, b) => {
      if (a.timing_from !== b.timing_from) {
        return a.timing_from - b.timing_from;
      }
      return a.minute_id - b.minute_id;
    });
    if (lastInTheEnd) {
      const interventionWithHighestMinuteId = orderedInterventions.reduce((prev, current) => {
        return prev.minute_id > current.minute_id ? prev : current;
      });
      if (interventionWithHighestMinuteId.timing_from === 0) {
        const index = orderedInterventions.indexOf(interventionWithHighestMinuteId);
        orderedInterventions.splice(index, 1);
        orderedInterventions.push(interventionWithHighestMinuteId);
      }
    }
    return orderedInterventions;
  };

  const reorderInterventionsInObject = (obj) => {
    return obj.map((item) => ({
      ...item,
      interventions: reorderInterventionsByTimingFrom(item.interventions),
    }));
  };

  const handleDayPoints = (response) => {
    setDayPoints(reorderInterventionsInObject(response.data));
  };

  const getData = (highlightItem = true) => {
    if (!highlightItem) {
      disableHihglight.current = true;
    }
    getMinutesService(plenarySessionId, (response) => handleDayPoints(response));
  };

  useEffect(() => {
    getData();
    if (reloadPointsAndMinutes === false) {
      setReloadPointsAndMinutes(true);
    }
  }, [reloadPointsAndMinutes]);

  useEffect(() => {
    if (disableHihglight.current) {
      disableHihglight.current = false;
      return;
    }
    setBackgroundColor("rgba(23, 162, 184, 0.1)");
    const timeoutId = setTimeout(() => {
      setBackgroundColor("white");
    }, 2000);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [dayPoints]);

const handleAccordionChange = (event, panel, isExpanded, force = false) => {
const newExpanded = isExpanded
? [...expanded, panel]
: expanded.filter((item) => item !== panel);
setExpanded(newExpanded);
    setActiveState((prevActiveState) => ({
      ...prevActiveState,
    activeAccordion: newExpanded,
activeLi: null,
}));
if (isExpanded) {
scrollToAccordion(panel);
}
};

  const handleLiClick = (event, accordionId, liId) => {
    event.stopPropagation();
    setActiveState({
      activeAccordion: accordionId,
      activeLi: liId,
    });
  };

  const handleCancelCreation = () => {
    handleAccordionChange(null, activeState.activeAccordion, !activeState.activeAccordion);
  };

  const handleOpenDeleteInterventionModal = (minute) => {
    setMinutes(minute);
    setShowDeleteModal(true);
  };

  const handleDeleteIntervention = (minute) => {
    deleteIntervention(plenarySessionId, minute, getData);
    setShowDeleteModal(false);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const createAgendaIntervention = (point) => {
    setCreateIntervention(point);
    getData();
  };

  const handleAssignTranscriptions = () => {
    assignTranscriptionService(plenarySessionId);
  };

  const handleReorderInterventions = (agendaPointId) => {
    getMinutesService(plenarySessionId, (response) => {
      const updatedDayPoints = reorderInterventionsInObject(response.data);
      setDayPoints(updatedDayPoints);
      setHasChangedMap((prevMap) => {
        const newMap = { ...prevMap };
        Object.keys(newMap).forEach((key) => {
          newMap[key] = false;
        });
        return newMap;
      });
    });
  };

  const getZero = (value) => {
    return !!value ? value : 0;
  };

  const getVotes = (intervention, point) => {
    return (
      intervention.type === INTERVENTION_TYPE_VOTE && (
        <div>
          <div>
            {intl.formatMessage({ id: VOTE_RESULT_YES })} : {getZero(intervention.voting_result?.yes)}
          </div>
          <div>
            {intl.formatMessage({ id: VOTE_RESULT_NO })}: {getZero(intervention.voting_result?.no)}
          </div>
          <div>
            {intl.formatMessage({ id: VOTE_RESULT_ABSTENTION })}: {getZero(intervention.voting_result?.abstention)}
          </div>
          {!statusReviewedPublished && (
            <VotingModalComponent
              activeAgendaItemId={point.agenda_point_id}
              activeAgendaItemTitle={plenaryTitle}
              votingMinute={intervention.minute_id}
              plenarySessionId={plenarySessionId}
              voteYes={getZero(intervention.voting_result?.yes)}
              voteNo={getZero(intervention.voting_result?.no)}
              voteAbstention={getZero(intervention.voting_result?.abstention)}
              callBack={(data) => getData(data, false)}
            />
          )}
        </div>
      )
    );
  };

  const getLocalizedDescription = (point, locale) => {
    return point.item_description_translations?.[locale] || point.item_description;
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "var(--spacing-sm)" }}>
      <OffsetInitTimeComponent
        plenarySessionId={plenarySessionId}
        onReloadData={getData}
        editableByStatus={editableByStatus}
        buttonDisabled={buttonDisabled}
        statusReviewedPublished={statusReviewedPublished}
      />
      {dayPoints.map((point, key) => {
        const previousPoint = key > 0 ? dayPoints[key - 1] : null;
        const nextPoint = key < dayPoints.length - 1 ? dayPoints[key + 1] : null;
        let interventionWithMaxMinuteId;
        if (!!point && !!point.interventions.length) {
          interventionWithMaxMinuteId = point.interventions.reduce((prevIntervention, currentIntervention) => {
            return prevIntervention.minute_id > currentIntervention.minute_id ? prevIntervention : currentIntervention;
          });
        }
        return (
          <div key={key} className={style.container}>
            <Accordion key={point.agenda_point_id}
              expanded={expanded.includes(point.agenda_point_id)}
              ref={(ref) => (accordionRefs.current[point.agenda_point_id] = ref)}
>
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon
                    fontSize="large"
                    sx={{ fontSize: 60 }}
                    onClick={(event) => {
                      handleAccordionChange(event, point.agenda_point_id, !expanded.includes(point.agenda_point_id));
                    }}
                  />
                }
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                tabIndex={0}
              >
                <div className={style.accordionSummary}>
                  <TypographyComponent text={getLocalizedDescription(point, intl.locale)} variant={TEXT_VARIANT_P} />
                  <div className={style.editPointOfDay}>
                    <EditIntervention
                      agendaId={point.agenda_point_id}
                      audioUrl={point.audio_url}
                      minuteId={point.minute_id}
                      timingFrom={point.timing_from}
                      timingTo={point.timing_to}
                      videoUrl={point.video_url}
                      plenarySessionId={plenarySessionId}
                      videoRef={videoRef}
                      isIntervention={false}
                      editableByStatus={editableByStatus}
                      buttonDisabled={buttonDisabled}
                      statusReviewedPublished={statusReviewedPublished}
                      previousPoint={previousPoint}
                      nextPoint={nextPoint}
                      reloadData={getData}
                      setHasChanged={(hasChanged) =>
                        setHasChangedMap((prevMap) => ({
                          ...prevMap,
                          [point.agenda_point_id]: hasChanged,
                        }))
                      }
                    />
                  </div>
                  <div className={`${style.reorderButton} ${style.smallButton} ${style.alignRight}`}>
                    <Tooltip title={hasChangedMap[point.agenda_point_id] ? "" : intl.formatMessage({ id: TOOLTIP_REORDER_BUTTON_DISABLED })}>
                      <div>
                        <ButtonComponent
                          onClick={() => handleReorderInterventions(point.agenda_point_id)}
                          text={intl.formatMessage({ id: REORDER_BUTTON_ID })}
                          variant={hasChangedMap[point.agenda_point_id] ? BUTTON.VARIANT_PRIMARY : BUTTON.VARIANT_DISABLE}
                          size={BUTTON.SIZE__FIT_CONTENT}
                          disabled={!hasChangedMap[point.agenda_point_id]}
                          haveText={true}
                          icon={ICON_REFRESH}
                        />
                      </div>
                    </Tooltip>
                  </div>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <ul className={style.pointDayAccordionUl}>
                  {point?.interventions.map((intervention, index) => {
                    const previousIntervention = index > 0 ? point.interventions[index - 1] : null;
                    const nextIntervention = index < point.interventions.length - 1 ? point.interventions[index + 1] : null;
                    return (
                      <li
                        key={intervention.minute_id + point.agenda_point_id}
                        className={`${style.pointDayAccordionLi} ${intervention.minute_id === interventionWithMaxMinuteId.minute_id
                          ? `${style.selectIntervention} interventionSelectedClass`
                          : ""
                          }`}
                        style={{
                          backgroundColor:
                            intervention.minute_id === interventionWithMaxMinuteId.minute_id
                              ? backgroundColor
                              : "white",
                        }}
                      >
                        <div>
                          <TypographyComponent
                            text={`${index + 1}. ${intervention.name}`}
                            variant={TEXT_VARIANT_SPAN}
                            className={style.interventionName}
                          />
                        </div>
                        <div className={style.editIntervention}>
                          {getVotes(intervention, point)}
                          <EditIntervention
                            agendaId={point.agenda_point_id}
                            audioUrl={intervention.audio_url}
                            isSelected={activeState.activeLi === intervention.id}
                            minuteId={intervention.minute_id}
                            plenarySessionId={plenarySessionId}
                            timingFrom={intervention.timing_from}
                            timingTo={intervention.timing_to}
                            videoUrl={intervention.video_url}
                            videoRef={videoRef}
                            isIntervention
                            editableByStatus={editableByStatus}
                            buttonDisabled={buttonDisabled}
                            statusReviewedPublished={statusReviewedPublished}
                            previousPoint={previousIntervention}
                            nextPoint={nextIntervention}
                            reloadData={getData}
                            setHasChanged={(hasChanged) =>
                              setHasChangedMap((prevMap) => ({
                                ...prevMap,
                                [point.agenda_point_id]: hasChanged,
                              }))
                            }
                          />
                          <div className={style.interventionButton}>
                            <Tooltip
                              title={
                                statusReviewedPublished
                                  ? intl.formatMessage({ id: BUTTON_DISABLED_BY_SESSION_STATUS })
                                  : ""
                              }
                            >
                              <div>
                                <ButtonComponent
                                  onClick={() => handleOpenDeleteInterventionModal(intervention.minute_id)}
                                  disabled={!editableByStatus}
                                  variant={buttonDisabled}
                                  size={BUTTON.SIZE__FIT_CONTENT}
                                  icon={<DeleteForeverIcon />}
                                  haveText={false}
                                />
                              </div>
                            </Tooltip>
                          </div>
                        </div>
                      </li>
                    );
                  })}
                </ul>
                {canCreatePoint && (
                  <CreateIntervention
                    agendaId={point.agenda_point_id}
                    minuteId={point.minute_id}
                    plenarySessionId={plenarySessionId}
                    videoRef={videoRef}
                    closeCreation={createAgendaIntervention}
                    cancelCreation={handleCancelCreation}
                    interventionsOfPoints={interventionsOfPoints}
                    setInterventionsOfPoints={setInterventionsOfPoints}
                    reloadInterventionsAndMinutes={reloadInterventionsAndMinutes}
                    setReloadInterventionsAndMinutes={setReloadInterventionsAndMinutes}
                  />
                )}
              </AccordionDetails>
            </Accordion>
          </div>
        );
      })}
      <div className={style.assignTranscriptions}>
        <Tooltip title={statusReviewedPublished ? intl.formatMessage({ id: BUTTON_DISABLED_BY_SESSION_STATUS }) : !transcriptionEnabled ? intl.formatMessage({ id: MESSAGE_VALIDATION_NOT_TRANSCRIPTIONS }) : ""}>
          <div>
            <ButtonComponent
              onClick={handleAssignTranscriptions}
              text={intl.formatMessage({ id: PLENARY__SESSIONS__ASSIGN_TRANSCRIPTS })}
              disabled={!editableByStatus || !transcriptionEnabled}
              variant={!transcriptionEnabled ? BUTTON.VARIANT_DISABLE : buttonDisabled}
            />
          </div>
        </Tooltip>
      </div>
      <Modal
        open={showDeleteModal}
        onClose={handleCloseDeleteModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={ModalStyle.modalContainer}>
          <div style={ModalStyle.textContainer}>
            <TypographyComponent
              text={intl.formatMessage({ id: MINUTES_MANAGER__DELETE_MODAL__TITLE })}
              variant={TEXT_VARIANT_H2}
            />
            <TypographyComponent
              text={intl.formatMessage({ id: MINUTES_MANAGER__DELETE_MODAL__TEXT })}
              variant={TEXT_VARIANT_P}
            />
          </div>
          <div style={ModalStyle.buttonsContainer}>
            <ButtonComponent
              onClick={handleCloseDeleteModal}
              variant={BUTTON.VARIANT_SECONDARY}
              text={intl.formatMessage({ id: COMMON_CANCEL })}
              icon={<CancelOutlined />}
              size={BUTTON.SIZE__FIT_CONTENT}
            />
            <ButtonComponent
              onClick={() => handleDeleteIntervention(minutes)}
              variant={BUTTON.VARIANT_PRIMARY}
              text={intl.formatMessage({ id: COMMON__DELETE })}
              icon={<CheckCircleOutline />}
              size={BUTTON.SIZE__FIT_CONTENT}
            />
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default TranscriptionAssignerComponent;
