import { buildUrl, del, get, patch, post, put } from "../utils/axiosRequests";
import {
  APP_TENANT,
  DELETE_PLENARY_SESSION_DOCUMENTS_ENDPOINT,
  GET_LANGUAGES_ENDPOINT,
  GET_PLENARY_ROOMS_ENDPOINT,
  PLENARY_SESSION_ENDPOINT,
  PLENARY_SESSION_PEOPLE_ENDPOINT,
  PLENARY_SESSIONS_ENDPOINT,
  GET_PLENARY_STATUS_ENDPOINT,
  GET_PLENARY_USER_GROUPS,
  GET_ALLOWED_ENDPOINT,
  GET_REALM_ENDPOINT,
  PLENARY_SESSION_DOCUMENTS_ENDPOINT,
  UPDATE_PLENARY_SESSION_TRANSCRIPTION_ENDPOINT,
  UPDATE_PLENARY_SESSION_ENDPOINT,
  UPDATE_PLENARY_SESSION_SESSION_ENDPOINT,
  PLENARY_SESSION_MINUTE_ELEMENT_ENDPOINT,
  PLENARY_SESSION_MINUTE_ENDPOINT,
  PLENARY_SESSION_INIT_OFFSET_ENDPOINT,
  PLENARY_SESSION_TRANSCRIPTION_ENDPOINT,
  PLENARY_SESSION_ASSIGN_TRANSCRIPTION_ENDPOINT,
  PLENARY_SESSION_ORDER_POINT_ENDPOINT,
  PLENARY_SESSION_WITH_ID_ORDER_POINT_ENDPOINT,
  TENANT_ROOMS_WITH_ID,
  TENANT_MEMBERS,
  PLENARY_SESSION_AUTO_MIXING,
  PLENARY_SESSION_SEATS_CONFIGURED,
  PLENARY_SESSION_AGENDA,
  PLENARY_SESSION_AGENDA_ACTIVE,
  PLENARY_SESSION_PEOPLE_ACTIVE_STATE_ENDPOINT,
  PLENARY_SESSION_VOTING_STATE_ENDPOINT,
  PLENARY_SESSION_WITH_ID_ORDER_ALL_POINTS_POINT_ENDPOINT,
  REMOVE_MICROPHONE_ENDPOINT,
  ADD_BREAK_TIME_ENDPOINT,
  UPDATE_PLENARY_SESSION_DOCUMENT_ENDPOINT,
  GET_DOCUMENT_PURPOSES_ENDPOINT,
  GET_DOCUMENT_PURPOSE_BY_ID_ENDPOINT,
  WIDE_SHOT_ENDPOINT
} from "../utils/constants";

export const addPlenarySessions = (body, callback, errorCallback) => {
  const url = buildUrl(PLENARY_SESSIONS_ENDPOINT, { tenant: APP_TENANT });
  post(url, body, {}, true, callback, errorCallback);
};
export const getAllowedService = (callback, errorCallback) => {
  const url = buildUrl(GET_ALLOWED_ENDPOINT, { tenant: APP_TENANT });
  get(url, {}, false, callback, errorCallback);
};
export const getRealmService = (callback, errorCallback) => {
  const url = buildUrl(GET_REALM_ENDPOINT, { tenant: APP_TENANT });
  get(url, {}, false, callback, errorCallback);
};

export const getLanguagesService = (callback, errorCallback) => {
  const url = buildUrl(GET_LANGUAGES_ENDPOINT, { tenant: APP_TENANT });
  get(url, {}, false, callback, errorCallback);
};

export const getRooms = (callback, errorCallback) => {
  const url = buildUrl(GET_PLENARY_ROOMS_ENDPOINT, { tenant: APP_TENANT });
  get(url, {}, false, callback, errorCallback);
};

export const getSessionStatus = (callback, errorCallback) => {
  get(GET_PLENARY_STATUS_ENDPOINT, {}, false, callback, errorCallback, {}, true);
};

export const getSessionInfo = (plenarySessionId, callback, errorCallback) => {
  const url = buildUrl(PLENARY_SESSION_ENDPOINT, {
    plenarySessionId,
    tenant: APP_TENANT,
  });
  get(url, {}, false, callback, errorCallback);
};
const convertToSeconds = (data) => {
  return data / 1000;
};
export const getPlenarySessions = (filters, callback, errorCallback) => {
  let params = "";
  let url = "";
  const filtersArray = [];
  if (!!filters?.plenary_room_id) {
    filtersArray.push("plenary_room_id=" + filters.plenary_room_id);
  }
  if (!!filters?.init_date) {
    filtersArray.push("init_date=" + convertToSeconds(filters.init_date.getTime()));
  }
  if (!!filters?.end_date) {
    filtersArray.push("end_date=" + convertToSeconds(filters.end_date.getTime()));
  }
  if (!!filters?.session_status) {
    filtersArray.push("session_status=" + filters.session_status);
  }
  filtersArray.push("offset=" + (isNaN(filters.offset) ? 0 : filters.offset));
  filtersArray.push("limit=" + filters.limit);
  if (!!filtersArray?.length) {
    params = "?" + filtersArray.join("&");
  }
  url = buildUrl(PLENARY_SESSIONS_ENDPOINT, { tenant: APP_TENANT }) + params;
  get(url, {}, false, callback, errorCallback);
};

export const saveAgendas = (plenarySessionId, agendaList, callback, errorCallback) => {
  const url = buildUrl(UPDATE_PLENARY_SESSION_ENDPOINT, {
    plenarySessionId,
    tenant: APP_TENANT,
  });
  post(url, agendaList, {}, false, callback, errorCallback);
};

export const updateSessionStatus = (plenarySessionId, data, callback, errorCallback) => {
  const url = buildUrl(UPDATE_PLENARY_SESSION_SESSION_ENDPOINT, {
    plenarySessionId,
    tenant: APP_TENANT,
    status: data,
  });
  patch(url, {}, false, false, callback, errorCallback);
};

export const addSessionDocument = (data, plenarySessionId, callback, errorCallback) => {
  const url = buildUrl(PLENARY_SESSION_DOCUMENTS_ENDPOINT, {
    plenarySessionId,
    tenant: APP_TENANT,
  });
  post(url, data, {}, true, callback, errorCallback, {
    "Content-Type": "multipart/form-data",
  });
};

export const deleteSessionDocument = (plenarySessionId, documentId, callback, errorCallback) => {
  const url = buildUrl(DELETE_PLENARY_SESSION_DOCUMENTS_ENDPOINT, {
    documentId,
    plenarySessionId,
    tenant: APP_TENANT,
  });
  del(url, {}, false, callback, errorCallback);
};

export const createAgendaPointService = (plenarySessionId, data, callback, errorCallback) => {
  const url = buildUrl(PLENARY_SESSION_ORDER_POINT_ENDPOINT, {
    plenarySessionId,
    tenant: APP_TENANT,
  });
  post(url, data, false, false, callback, errorCallback);
};

export const updateAgendaPointService = (pointId, plenarySessionId, data, callback, errorCallback) => {
  const url = buildUrl(PLENARY_SESSION_WITH_ID_ORDER_POINT_ENDPOINT, {
    pointId,
    plenarySessionId,
    tenant: APP_TENANT,
  });
  patch(url, data, false, false, callback, errorCallback);
};

export const updateAgendaAllPointService = (data, plenarySessionId, callback, errorCallback) => {
  const url = buildUrl(PLENARY_SESSION_WITH_ID_ORDER_ALL_POINTS_POINT_ENDPOINT, {
    plenarySessionId,
    tenant: APP_TENANT,
  });
  patch(url, data, false, false, callback, errorCallback);
};

export const deleteAgendaPointService = (plenarySessionId, pointId, callback, errorCallback) => {
  const url = buildUrl(PLENARY_SESSION_WITH_ID_ORDER_POINT_ENDPOINT, {
    pointId,
    plenarySessionId,
    tenant: APP_TENANT,
  });
  del(url, {}, false, callback, errorCallback);
};

export const getSessionTranscriptions = (plenarySessionId, callback, errorCallback) => {
  const url = buildUrl(PLENARY_SESSION_TRANSCRIPTION_ENDPOINT, {
    plenarySessionId,
    tenant: APP_TENANT,
  });
  get(url, {}, false, callback, errorCallback);
};

export const updateTranscriptionService = (plenarySessionId, data, callback, errorCallback) => {
  const url = buildUrl(UPDATE_PLENARY_SESSION_TRANSCRIPTION_ENDPOINT, {
    plenarySessionId,
    tenant: APP_TENANT,
  });
  patch(url, data, false, false, callback, errorCallback);
};

/*export const getSessionPeople = (plenarySessionId, callback, errorCallback) => {
  const url = buildUrl(PLENARY_SESSION_PEOPLE_ENDPOINT, {
    plenarySessionId,
    tenant: APP_TENANT,
  });
  get(url, {}, false, callback, errorCallback);
};*/

export const getMinutesService = (plenarySessionId, callback, errorCallback) => {
  const url = buildUrl(PLENARY_SESSION_MINUTE_ENDPOINT, {
    plenarySessionId,
    tenant: APP_TENANT,
  });
  get(url, false, false, callback, errorCallback);
};
export const updateMinuteService = (plenarySessionId, minuteId, data, callback, errorCallback) => {
  const url = buildUrl(PLENARY_SESSION_MINUTE_ELEMENT_ENDPOINT, {
    minute: minuteId,
    plenarySessionId,
    tenant: APP_TENANT,
  });
  patch(url, data, {}, false, callback, errorCallback);
};
export const createMinuteService = (plenarySessionId, data, callback, errorCallback) => {
  const url = buildUrl(PLENARY_SESSION_MINUTE_ENDPOINT, {
    plenarySessionId,
    tenant: APP_TENANT,
  });
  post(url, data, false, false, callback, errorCallback);
};

export const deleteIntervention = (plenarySessionId, minute, callback) => {
  const url = buildUrl(PLENARY_SESSION_MINUTE_ELEMENT_ENDPOINT, {
    minute,
    plenarySessionId,
    tenant: APP_TENANT,
  });
  del(url, {}, false, callback);
};

export const updatePlenaryInformation = (plenarySessionId, data, callback, errorCallback) => {
  const url = buildUrl(PLENARY_SESSION_ENDPOINT, {
    plenarySessionId,
    tenant: APP_TENANT,
  });
  patch(url, data, {}, false, callback, errorCallback);
};

export const getDocumentService = (url, callback, errorCallback) => {
  get(url, {}, false, callback, errorCallback);
};
export const updateInitOffsetService = (plenarySessionId, offset, callback, errorCallback) => {
  const url = buildUrl(PLENARY_SESSION_INIT_OFFSET_ENDPOINT, {
    offset,
    plenarySessionId,
    tenant: APP_TENANT,
  });
  post(url, false, false, false, callback, errorCallback);
};
export const assignTranscriptionService = (plenarySessionId, data, callback, errorCallback) => {
  const url = buildUrl(PLENARY_SESSION_ASSIGN_TRANSCRIPTION_ENDPOINT, {
    plenarySessionId,
    tenant: APP_TENANT,
  });
  post(url, {}, {}, false, callback, errorCallback);
};
export const getUserGroupsService = (callback, errorCallback) => {
  const url = buildUrl(GET_PLENARY_USER_GROUPS, { tenant: APP_TENANT });
  get(url, {}, false, callback, errorCallback);
};

export const getRoomsService = (idRoom, callback, errorCallback) => {
  const url = buildUrl(TENANT_ROOMS_WITH_ID, { idRoom, tenant: APP_TENANT });
  get(url, {}, false, callback, errorCallback);
};
export const getMembersService = (callback, errorCallback) => {
  const url = buildUrl(TENANT_MEMBERS, { tenant: APP_TENANT });
  get(url, {}, false, callback, errorCallback);
};

export const saveParticipantService = (data, plenarySessionId, callback, errorCallback) => {
  const url = buildUrl(PLENARY_SESSION_PEOPLE_ENDPOINT, { plenarySessionId, tenant: APP_TENANT });
  patch(url, data, {}, false, callback, errorCallback);
};
export const saveNewParticipantService = (data, plenarySessionId, callback, errorCallback) => {
  const url = buildUrl(PLENARY_SESSION_PEOPLE_ENDPOINT, { plenarySessionId, tenant: APP_TENANT });
  put(url, data, {}, false, callback, errorCallback);
};

export const updateAutoMixingService = (plenarySessionId, data, callback, errorCallback) => {
  const url = buildUrl(PLENARY_SESSION_AUTO_MIXING, { plenarySessionId, tenant: APP_TENANT });
  patch(url, data, {}, false, callback, errorCallback);
};
export const getIsSeatsConfiguredService = (plenarySessionId, callback, errorCallback) => {
  const url = buildUrl(PLENARY_SESSION_SEATS_CONFIGURED, { plenarySessionId, tenant: APP_TENANT });
  get(url, {}, false, callback, errorCallback);
};
export const getAgendaService = (plenarySessionId, callback, errorCallback) => {
  const url = buildUrl(PLENARY_SESSION_AGENDA, { plenarySessionId, tenant: APP_TENANT });
  get(url, {}, false, callback, errorCallback);
};
export const updateActiveStateAgendaService = (plenarySessionId, agendaPointId, callback, errorCallback) => {
  const url = buildUrl(PLENARY_SESSION_AGENDA_ACTIVE, { plenarySessionId, agendaPointId, tenant: APP_TENANT });
  post(url, {}, {}, false, callback, errorCallback);
};

export const getParticipantsService = (plenarySessionId, callback, errorCallback) => {
  const url = buildUrl(PLENARY_SESSION_PEOPLE_ENDPOINT, { plenarySessionId, tenant: APP_TENANT });
  get(url, {}, false, callback, errorCallback);
};

export const updateActiveStateParticipantService = (plenarySessionId, participantId, callback, errorCallback) => {
  const url = buildUrl(PLENARY_SESSION_PEOPLE_ACTIVE_STATE_ENDPOINT, {
    plenarySessionId,
    participantId,
    tenant: APP_TENANT,
  });
  post(url, {}, {}, false, callback, errorCallback);
};
export const votingService = (plenarySessionId, votingMinute, data, callback, errorCallback) => {
  const url = buildUrl(PLENARY_SESSION_VOTING_STATE_ENDPOINT, {
    plenarySessionId,
    votingMinute,
    tenant: APP_TENANT,
  });
  post(url, data, {}, false, callback, errorCallback);
};

export const deleteSessionService = (plenarySessionId, callback, errorCallback) => {
  const url = buildUrl(PLENARY_SESSION_ENDPOINT, {
    plenarySessionId,
    tenant: APP_TENANT,
  });
  del(url, {}, false, callback, errorCallback);
};
export const removeParticipantMicrophoneService = (tenantId, plenarySessionId, participantId, callback, errorCallback) => {
  const url = buildUrl(REMOVE_MICROPHONE_ENDPOINT, {
    tenant: tenantId,
    plenarySessionId: plenarySessionId,
    participantId: participantId,
  });

  return del(url, {}, false, callback, errorCallback);
};

export const addBreakTimeService = (plenarySessionId, data, callback, errorCallback) => {
  const url = buildUrl(ADD_BREAK_TIME_ENDPOINT, { plenarySessionId, tenant: APP_TENANT });
  post(url, data, {}, false, callback, errorCallback);
};

export const updateSessionDocument = (plenarySessionId, documentId, data, callback, errorCallback) => {
  const url = buildUrl(UPDATE_PLENARY_SESSION_DOCUMENT_ENDPOINT, {
    file_id: documentId,
    plenary_session_id: plenarySessionId,
    tenant_name: APP_TENANT,
  });
  patch(url, data, {}, false, callback, errorCallback);
};

export const getDocumentPurposes = (callback, errorCallback) => {
    get(GET_DOCUMENT_PURPOSES_ENDPOINT, {}, false, (response) => {
        if (Array.isArray(response.data)) {
            callback(response.data);
        } else {
            callback([]);
        }
    }, errorCallback);
};

export const getDocumentPurposeById = (purposeId, callback, errorCallback) => {
    if (!purposeId) {
        errorCallback("Invalid purposeId");
        return;
    }
    const url = buildUrl(GET_DOCUMENT_PURPOSE_BY_ID_ENDPOINT, { purpose_id: purposeId });
    get(url, {}, false, callback, errorCallback);
};

export const getPlenarySessionFiles = (plenarySessionId, callback, errorCallback) => {
    const url = buildUrl(PLENARY_SESSION_DOCUMENTS_ENDPOINT, {
        plenarySessionId,
        tenant: APP_TENANT,
    });
    get(url, {}, false, callback, errorCallback);
};
export const sendWideShotRequest = (tenant, plenarySessionId, callback, errorCallback) => {
  const url = buildUrl(WIDE_SHOT_ENDPOINT, {
    tenant,
    plenarySessionId,
  });
  post(url, {}, {}, false, callback, errorCallback);
};
